import { client, restClient } from "./axios";
import { prop, propOr } from "ramda";

export const project = {
  addStage: ({ projectId, stage }) => {
    return restClient
      .post(`/projects/${projectId}/stages`, { stage })
      .then((res) => propOr({}, "data", res));
  },
  updateStage: ({ projectId, stage }) => {
    return restClient
      .put(`/projects/${projectId}/stages/${stage.id}`, { stage })
      .then((res) => propOr({}, "data", res));
  },
  deleteStage: ({ projectId, stageId }) => {
    return restClient
      .delete(`/projects/${projectId}/stages/${stageId}`)
      .then((res) => propOr({}, "data", res));
  },
  createRisk: ({ id, data }) => {
    return restClient
      .post(`/projects/${id}/risks`, data)
      .then((res) => propOr({}, "data", res));
  },
  editRisk: ({ id, riskId, data }) => {
    return restClient
      .put(`/projects/${id}/risks/${riskId}`, data)
      .then((res) => propOr({}, "data", res));
  },
  addLesson: ({ projectId, lesson, useRestApiV1 }) => {
    return (useRestApiV1 ? restClient : client)
      .post(`/projects/${projectId}/lessons`, { lesson })
      .then((res) => propOr({}, "data", res));
  },
  updateLesson: ({ projectId, lessonId, lesson, useRestApiV1 }) => {
    return (useRestApiV1 ? restClient : client)
      .put(`/projects/${projectId}/lessons/${lessonId}`, { lesson })
      .then((res) => propOr({}, "data", res));
  },
  deleteLesson: ({ projectId, lessonId, useRestApiV1 }) => {
    return (useRestApiV1 ? restClient : client)
      .delete(`/projects/${projectId}/lessons/${lessonId}`)
      .then((res) => propOr({}, "data", res));
  },
  archiveLesson: ({ projectId, lessonId, lesson }) => {
    return restClient
      .put(`/projects/${projectId}/lessons/${lessonId}`, { lesson })
      .then((res) => propOr({}, "data", res));
  },
  unarchiveLesson: ({ projectId, lessonId, lesson }) => {
    return restClient
      .put(`/projects/${projectId}/lessons/${lessonId}`, { lesson })
      .then((res) => propOr({}, "data", res));
  },
  add: (data) => {
    return client
      .post("/projects", data)
      .then((res) => propOr({}, "data", res));
  },
  addFavorite: (data) => {
    return client.post(
      "/favorites",
      {
        project: {
          id: data.id,
        },
      },
      data.useCommand
        ? {
            headers: {
              command: true,
            },
          }
        : undefined,
    );
  },
  removeFavorite: ({ id }) => {
    return client.delete(`/favorites/${id}`);
  },
  single: ({ id }) => {
    return client.get(`/projects/${id}`).then((res) => propOr({}, "data", res));
  },
  list: () => {
    return client.get(`/v1/projects/`).then((res) => propOr({}, "data", res));
  },
  links: ({ id }) => {
    return client
      .get(`/projects/${id}/links`)
      .then((res) => propOr({}, "data", res));
  },
  addLink: ({ id, data }) => {
    return client
      .post(`/projects/${id}/links`, data)
      .then((res) => propOr({}, "data", res));
  },
  editLink: ({ id, linkId, data }) => {
    return client
      .put(`/projects/${id}/links/${linkId}`, data)
      .then((res) => propOr({}, "data", res));
  },
  editProperties: ({ id, data }) => {
    return client
      .put(`/projects/${id}/properties`, data)
      .then((res) => propOr({}, "data", res));
  },
  deleteLink: ({ id, linkId }) => {
    return client
      .delete(`/projects/${id}/links/${linkId}`)
      .then((res) => propOr({}, "data", res));
  },
  roles: ({ id }) => {
    return client
      .get(`/projects/${id}/roles`)
      .then((res) => propOr({}, "data", res));
  },
  addRole: ({ id, data }) => {
    return client
      .post(`/projects/${id}/roles`, data)
      .then((res) => propOr({}, "data", res));
  },
  editRole: ({ id, roleId, data }) => {
    return client
      .put(`/projects/${id}/roles/${roleId}`, data)
      .then((res) => propOr({}, "data", res));
  },
  deleteRole: ({ id, roleId, data }) => {
    return client
      .delete(`/projects/${id}/roles/${roleId}`, { data: data })
      .then((res) => propOr({}, "data", res));
  },
  products: ({ id }) => {
    return client
      .get(`/projects/${id}/products`)
      .then((res) => propOr([], "data", res));
  },
  updateBackground: (data) => {
    return client
      .post("/projects", data)
      .then((res) => propOr({}, "data", res));
  },
  updateObjectives: (data) => {
    return client
      .post("/projects", data)
      .then((res) => propOr({}, "data", res));
  },
  updateScope: (data) => {
    return client
      .post("/projects", data)
      .then((res) => propOr({}, "data", res));
  },
  updateConstraints: (data) => {
    return client
      .post("/projects", data)
      .then((res) => propOr({}, "data", res));
  },
  createHighlightReport: ({ projectId, ...data }) => {
    return client
      .post(`/projects/${projectId}/progress/highlights`, data)
      .then((res) => propOr({}, "data", res));
  },
  editHighlightReport: ({ projectId, reportId, ...data }) => {
    return client
      .put(`/projects/${projectId}/progress/highlights/${reportId}`, data)
      .then((res) => propOr({}, "data", res));
  },
  createCheckpointReport: ({ projectId, useRestApiV1, ...data }) => {
    console.log(data);
    return useRestApiV1
      ? restClient
          .post(`/projects/${projectId}/checkpoints`, data)
          .then((res) => propOr({}, "data", res))
      : client
          .post(`/projects/${projectId}/checkpoints`, data)
          .then((res) => propOr({}, "data", res));
  },
  stageAction: ({ projectId, stageId, action }) => {
    console.log({ projectId, stageId, action });
    return client
      .post(`/projects/${projectId}/stages/${stageId}/${action}`)
      .then((res) => propOr({}, "data", res));
  },
  stageAssignActuals: ({ projectId, stageId, cost, period }) => {
    return client
      .put(`/projects/${projectId}/stages/${stageId}/assignActuals`, {
        cost,
        period,
      })
      .then((res) => propOr({}, "data", res));
  },
  requestExceptionApproval: ({
    projectId,
    level,
    summary,
    exceptionReport,
  }) => {
    return client
      .post(`/projects/${projectId}/requestExceptionApproval`, {
        level,
        summary,
        exceptionReport,
      })
      .then((res) => propOr({}, "data", res));
  },
  updateExceptionPlan: ({
    projectId,
    exceptionPlanId,
    level,
    summary,
    exceptionReport,
  }) => {
    return client
      .put(
        `/projects/${projectId}/exceptionPlans/${exceptionPlanId}/requestExceptionApproval`,
        {
          level,
          summary,
          exceptionReport,
        },
      )
      .then((res) => propOr({}, "data", res));
  },
  approveException: ({ id, projectId, stageId }) => {
    return client
      .post(`/projects/${projectId}/stages/${stageId}/approveException`, { id })
      .then((res) => propOr({}, "data", res));
  },
  quality: {
    approach: ({ projectId }) => {
      return client
        .get(`/projects/${projectId}/quality/approach`)
        .then((res) => propOr({}, "data", res));
    },
    updateApproach: ({ projectId, approach }) => {
      console.log(
        "[INFO] Update quality approach " +
          projectId +
          " " +
          JSON.stringify(approach),
      );
      return client
        .put(`/projects/${projectId}/quality/approach`, { approach })
        .then((res) => propOr({}, "data", res));
    },
  },
  report: {
    checkpoints: ({ id }) => {
      return client
        .get(`/projects/${id}/checkpoints`)
        .then((res) => propOr({}, "data", res));
    },
    highlights: ({ id }) => {
      return client
        .get(`/projects/${id}/highlights`)
        .then((res) => propOr({}, "data", res));
    },
    single: ({ id, type, reportId }) => {
      return client
        .get(`/projects/${id}/reports/${type}/${reportId}`)
        .then((res) => propOr({}, "data", res));
    },
    list: ({ id, type }) => {
      return client
        .get(`/projects/${id}/progress/reports`)
        .then((res) => propOr({}, "data", res));
    },
  },
  risks: ({ id }) => {
    return client
      .get(`/projects/${id}/risks`)
      .then((res) => propOr([], "data", res));
  },
  issues: ({ id }) => {
    return client
      .get(`/projects/${id}/issues`)
      .then((res) => propOr([], "data", res));
  },
  createIssue: ({ id, data }) => {
    return client
      .post(`/projects/${id}/issues`, data)
      .then((res) => propOr({}, "data", res));
  },
  editIssue: ({ id, issueId, data }) => {
    return client
      .put(`/projects/${id}/issues/${issueId}`, data)
      .then((res) => propOr({}, "data", res));
  },
  createIssueReport: ({ id, issueId, data }) => {
    return client
      .post(`/projects/${id}/issues/${issueId}/reports`, data)
      .then((res) => propOr({}, "data", res));
  },
  plans: ({ id }) => {
    return client
      .get(`/projects/${id}/plans`)
      .then((res) => propOr([], "data", res));
  },
  lessons: () => {
    return client.get(`/lessons`).then((res) => propOr([], "data", res));
  },
  applyLesson: ({ projectId, lessonId }) => {
    return client
      .put(`/projects/${projectId}/lessons/${lessonId}/apply`)
      .then((res) => propOr({}, "data", res));
  },
  unapplyLesson: ({ projectId, lessonId }) => {
    return client
      .put(`/projects/${projectId}/lessons/${lessonId}/unapply`)
      .then((res) => propOr({}, "data", res));
  },
  _archiveLessonDeprecated: ({ projectId, lessonId, useRestApiV1 }) => {
    return client
      .put(`/projects/${projectId}/lessons/${lessonId}/archive`)
      .then((res) => propOr({}, "data", res));
  },
  _unarchiveLessonDeprecated: ({ projectId, lessonId }) => {
    return client
      .put(`/projects/${projectId}/lessons/${lessonId}/unarchive`)
      .then((res) => propOr({}, "data", res));
  },
  controls: ({ id }) => {
    return client
      .get(`/projects/${id}/controls`)
      .then((res) => propOr([], "data", res));
  },
  updateControls: ({ projectId, controls }) => {
    return client
      .put(`/projects/${projectId}/plans/controls`, { controls })
      .then((res) => propOr({}, "data", res));
  },
  approach: ({ id }) => {
    return client
      .get(`/projects/${id}/approach`)
      .then((res) => propOr([], "data", res));
  },
  addApproach: ({ projectId, approach }) => {
    return client
      .post(`/projects/${projectId}/plans/approach`, { approach })
      .then((res) => propOr({}, "data", res));
  },
  updateApproach: ({ projectId, approach }) => {
    return client
      .put(`/projects/${projectId}/plans/approach`, { approach })
      .then((res) => propOr({}, "data", res));
  },
  organizationApproach: ({ id }) => {
    return client
      .get(`/projects/${id}/organization/approach`)
      .then((res) => propOr([], "data", res));
  },
  updateOrganizationApproach: ({ projectId, approach }) => {
    return client
      .put(`/projects/${projectId}/organization/approach`, { approach })
      .then((res) => propOr({}, "data", res));
  },
  riskApproach: ({ id }) => {
    return client
      .get(`/projects/${id}/risk/approach`)
      .then((res) => propOr([], "data", res));
  },
  updateRiskApproach: ({ projectId, approach }) => {
    return client
      .put(`/projects/${projectId}/risk/approach`, { approach })
      .then((res) => propOr({}, "data", res));
  },
  changeApproach: ({ id }) => {
    return client
      .get(`/projects/${id}/change/approach`)
      .then((res) => propOr([], "data", res));
  },
  updateChangeApproach: ({ projectId, approach }) => {
    return client
      .put(`/projects/${projectId}/change/approach`, { approach })
      .then((res) => propOr({}, "data", res));
  },
  scopes: ({ id }) => {
    return client
      .get(`/projects/${id}/scopes`)
      .then((res) => propOr([], "data", res));
  },
  addScopeItem: ({ id, scopeId, data }) => {
    return client
      .post(`/projects/${id}/scopes/${scopeId}/items`, data)
      .then((res) => propOr({}, "data", res));
  },
  editScopeItem: ({ id, scopeId, itemId, data }) => {
    return client
      .put(`/projects/${id}/scopes/${scopeId}/items/${itemId}`, data)
      .then((res) => propOr({}, "data", res));
  },
  deleteScopeItem: ({ id, scopeId, itemId }) => {
    return client
      .delete(`/projects/${id}/scopes/${scopeId}/items/${itemId}`)
      .then((res) => propOr({}, "data", res));
  },
  updateDefinition: ({ id, data }) => {
    return client
      .put(`/projects/${id}/definition`, data)
      .then((res) => propOr({}, "data", res));
  },
  approvePID: ({ id, data }) => {
    return client
      .put(`/projects/${id}/pid/approve`, data)
      .then((res) => propOr({}, "data", res));
  },
  approveBrief: ({ id, data }) => {
    return client
      .put(`/projects/${id}/brief/approve`, data)
      .then((res) => propOr({}, "data", res));
  },
  draftBrief: ({ id, data }) => {
    return client
      .post(`/projects/${id}/brief/createDraft`, data)
      .then((res) => propOr({}, "data", res));
  },
  draftPID: ({ id, data }) => {
    return client
      .post(`/projects/${id}/pid/createDraft`, data)
      .then((res) => propOr({}, "data", res));
  },
  addStakeholder: ({ id, data }) => {
    return client
      .post(`/projects/${id}/stakeholders`, data)
      .then((res) => propOr({}, "data", res));
  },
  updateStakeholder: ({ id, stakeholderId, data }) => {
    return client
      .put(`/projects/${id}/stakeholders/${stakeholderId}`, data)
      .then((res) => propOr({}, "data", res));
  },
  businessCase: ({ id }) => {
    return client
      .get(`/projects/${id}/businessCase`)
      .then((res) => propOr({}, "data", res));
  },
  updateBusinessCase: ({ id, data }) => {
    return client
      .put(`/projects/${id}/businessCase`, data)
      .then((res) => propOr({}, "data", res));
  },
  toggleBusinessCase: ({ id, data }) => {
    return client
      .put(`/projects/${id}/settings/businessCase`, data)
      .then((res) => propOr({}, "data", res));
  },
  pause: ({ id, data }) => {
    return client
      .post(`/projects/${id}/pause`, data)
      .then((res) => propOr({}, "data", res));
  },
  start: ({ id, data }) => {
    return client
      .post(`/projects/${id}/start`, data)
      .then((res) => propOr({}, "data", res));
  },
  stop: ({ id, data }) => {
    return client
      .post(`/projects/${id}/stop`, data)
      .then((res) => propOr({}, "data", res));
  },
  prepareClosure: ({ id, data }) => {
    return client
      .post(`/projects/${id}/prepareClosure`, data)
      .then((res) => propOr({}, "data", res));
  },
  recommendClosure: ({ id, data }) => {
    return client
      .post(`/projects/${id}/recommendClosure`, data)
      .then((res) => propOr({}, "data", res));
  },
  abortClosure: ({ id, data }) => {
    return client
      .post(`/projects/${id}/abortClosure`, data)
      .then((res) => propOr({}, "data", res));
  },
  approveClosure: ({ id }) => {
    return client
      .post(`/projects/${id}/closures`, {})
      .then((res) => propOr({}, "data", res));
  },
  log: ({ id }) => {
    return client
      .get(`/projects/${id}/log`)
      .then((res) => propOr({}, "data", res));
  },
  logDetails: ({ id, logId, language }) => {
    return client
      .get(`/projects/${id}/log/${logId}`, {
        params: {
          language,
        },
      })
      .then((res) => propOr({}, "data", res));
  },
  addProduct: ({ id, data }) => {
    return restClient
      .post(`/projects/${id}/output/product`, data)
      .then((res) => propOr({}, "data", res));
  },
  updateProduct: ({ id, data }) => {
    return restClient
      .put(`/projects/${id}/output/product`, data)
      .then((res) => propOr({}, "data", res));
  },
};

export const portfolio = {
  single: ({ id }) => {
    return client
      .get(`/portfolios/${id}`)
      .then((res) => propOr({}, "data", res));
  },
  list: () => {
    return client.get(`/portfolios`).then((res) => propOr({}, "data", res));
  },
  create: (data) => {
    return client.post("/portfolios", data);
  },
  update: ({ id, ...data }) => {
    return client.put(`/portfolios/${id}`, data);
  },
  delete: ({ id }) => {
    return client.delete(`/portfolios/${id}`);
  },
  accessControl: ({ id, ...data }) => {
    return client.put(`/portfolios/${id}/accesscontrol`, data);
  },
  prioritize: ({ portfolioId, itemId, position }) => {
    return client.post(`/portfolios/${portfolioId}/prioritize`, {
      id: itemId,
      position,
    });
  },
};

export const phase = {
  create: ({ projectTypeId, ...data }) => {
    return client.post(`/projectTypes/${projectTypeId}/phases`, data);
  },
  update: ({ projectTypeId, id, ...data }) => {
    return client.put(`/projectTypes/${projectTypeId}/phases/${id}`, data);
  },
};

export const tailoring = {
  category: ({ id, ...data }) => {
    return client.post(`/categories/${id}/tailoring`, data);
  },
};

export const users = {
  all: () => {
    return client.get(`/users`).then((res) => propOr({}, "data", res));
  },
  me: () => {
    return client.get(`/users/me`).then((res) => propOr({}, "data", res));
  },
  switchTenant: (data) => {
    return client
      .post("/users/switchTenant", data)
      .then((res) => propOr({}, "data", res));
  },
  updateAccess: ({ id, ...data }) => {
    return client.put(`/users/${id}`, data);
  },
  add: ({ ...data }) => {
    return restClient.post(`/users`, data);
  },
  delete: ({ id }) => {
    return client.delete(`/users/${id}`);
  },
  toggleBusinessCase: (...data) => {
    return client
      .put(`/accountsettings/businessCase`, ...data)
      .then((res) => propOr({}, "data", res));
  },
  businessCase: () => {
    return client
      .get(`/tenantsettings/businesscase`)
      .then((res) => propOr({}, "data", res));
  },
};

export const customers = {
  add: ({ ...data }) => {
    return restClient.post(`/customers`, data);
  },
};

export const home = {
  favorites: () => {
    return client.get("/favorites").then((res) => propOr([], "data", res));
  },
  search: ({ query }) => {
    return client
      .get("/search", {
        params: {
          criteria: query,
        },
      })
      .then((res) => propOr([], "data", res));
  },
  reports: () => {
    return client.get("/reports").then((res) => propOr([], "data", res));
  },
};

export const login = {
  requestLink: (data) => {
    return client.post("/requestLoginToken", data);
  },
  requestAuth: ({ code, ...data }) => {
    return client.post(`/login/${code}`);
  },
};

export const access = {
  logout: () => {
    console.log("Call logout");
    client.get("/logout");
  },
};

export const location = {
  create: (data) => {
    return client.post("/locations", data);
  },
  list: () => {
    return client.get(`/locations`).then((res) => propOr({}, "data", res));
  },
  update: ({ id, ...data }) => {
    return client.put(`/locations/${id}`, data);
  },
  delete: ({ id }) => {
    return client.delete(`/locations/${id}`);
  },
};

export const department = {
  create: (data) => {
    return client.post("/departments", data);
  },
  list: () => {
    return client.get(`/departments`).then((res) => propOr({}, "data", res));
  },
  update: ({ id, ...data }) => {
    return client.put(`/departments/${id}`, data);
  },
  delete: ({ id }) => {
    return client.delete(`/departments/${id}`);
  },
};

export const currency = {
  create: (data) => {
    return client.post("/currencies", data);
  },
  list: () => {
    return client.get(`/currencies`).then((res) => propOr({}, "data", res));
  },
};

export const currencyType = {
  list: () => {
    return client.get(`/currencyTypes`).then((res) => propOr({}, "data", res));
  },
};

export const projectType = {
  create: (data) => {
    return client.post("/projectTypes", data);
  },
  list: () => {
    return client.get(`/projectTypes`).then((res) => propOr({}, "data", res));
  },
  update: ({ id, ...data }) => {
    return client.put(`/projectTypes/${id}`, data);
  },
  delete: ({ id }) => {
    return client.delete(`/projectTypes/${id}`);
  },
};

export const category = {
  create: (data) => {
    return client.post("/categories", data);
  },
  list: () => {
    return client.get(`/categories`).then((res) => propOr({}, "data", res));
  },
  update: ({ id, ...data }) => {
    return client.put(`/categories/${id}`, data);
  },
  delete: ({ id }) => {
    return client.delete(`/categories/${id}`);
  },
};

export const standardLink = {
  create: (data) => {
    return client.post("/standardLinks", data);
  },
  list: () => {
    return client.get(`/standardLinks`).then((res) => propOr({}, "data", res));
  },
  update: ({ id, ...data }) => {
    return client.put(`/standardLinks/${id}`, data);
  },
  delete: ({ id }) => {
    return client.delete(`/standardLinks/${id}`);
  },
};

export const product = {
  add: (data) => {
    return client.post("/products", data);
  },
  addFavorite: (data) => {
    return client.post("/favorites", {
      product: {
        id: data.id,
      },
    });
  },
  removeFavorite: ({ id }) => {
    return client.delete(`/favorites/${id}`);
  },
  single: ({ id }) => {
    return client.get(`/products/${id}`).then((res) => propOr({}, "data", res));
  },
  links: ({ id }) => {
    return client
      .get(`/products/${id}/links`)
      .then((res) => propOr({}, "data", res));
  },
  addLink: ({ id, data }) => {
    return client
      .post(`/products/${id}/links`, data)
      .then((res) => propOr({}, "data", res));
  },
  editLink: ({ id, linkId, data }) => {
    return client
      .put(`/products/${id}/links/${linkId}`, data)
      .then((res) => propOr({}, "data", res));
  },
  deleteLink: ({ id, linkId }) => {
    return client
      .delete(`/products/${id}/links/${linkId}`)
      .then((res) => propOr({}, "data", res));
  },
  releases: ({ id }) => {
    return client
      .get(`/products/${id}/releases`)
      .then((res) => propOr([], "data", res));
  },
  addRelease: ({ productId, release }) => {
    return client
      .post(`/products/${productId}/releases`, { release })
      .then((res) => propOr({}, "data", res));
  },
  updateRelease: ({ productId, release }) => {
    return client
      .put(`/products/${productId}/releases`, { release })
      .then((res) => propOr({}, "data", res));
  },
  completeRelease: ({ productId, releaseId }) => {
    return client
      .put(`/products/${productId}/releases/${releaseId}`, { release })
      .then((res) => propOr({}, "data", res));
  },
  deleteRelease: ({ productId, releaseId }) => {
    return client
      .delete(`/products/${productId}/releases/${releaseId}`)
      .then((res) => propOr({}, "data", res));
  },
  startReleaseWithMethod: ({ productId, releaseId, method, projectId }) => {
    return client
      .post(`/products/${productId}/releases/${releaseId}/start`, {
        method,
        projectId,
      })
      .then((res) => propOr({}, "data", res));
  },
  stageAssignActuals: ({ productId, stageId, cost, period }) => {
    return client
      .put(`/products/${productId}/stages/${stageId}/assignActuals`, {
        cost,
        period,
      })
      .then((res) => propOr({}, "data", res));
  },
  report: {
    highlights: ({ id }) => {
      return client
        .get(`/products/${id}/highlights`)
        .then((res) => propOr({}, "data", res));
    },
  },
  risks: ({ id }) => {
    return client
      .get(`/products/${id}/risks`)
      .then((res) => propOr([], "data", res));
  },
  createRisk: ({ id, data }) => {
    return client
      .post(`/products/${id}/risks`, data)
      .then((res) => propOr({}, "data", res));
  },
  editRisk: ({ id, riskId, data }) => {
    return restClient
      .put(`/products/${id}/risks/${riskId}`, data)
      .then((res) => propOr({}, "data", res));
  },
  issues: ({ id }) => {
    return client
      .get(`/products/${id}/issues`)
      .then((res) => propOr([], "data", res));
  },
  createIssue: ({ id, data }) => {
    return client
      .post(`/products/${id}/issues`, data)
      .then((res) => propOr({}, "data", res));
  },
  editIssue: ({ id, issueId, data }) => {
    return client
      .put(`/products/${id}/issues/${issueId}`, data)
      .then((res) => propOr({}, "data", res));
  },
  createHighlightReport: ({ productId, ...data }) => {
    return client
      .post(`/products/${productId}/progress/highlights`, data)
      .then((res) => propOr({}, "data", res));
  },
  editHighlightReport: ({ productId, reportId, ...data }) => {
    return client
      .put(`/products/${productId}/progress/highlights/${reportId}`, data)
      .then((res) => propOr({}, "data", res));
  },
  updateDefinition: ({ id, data }) => {
    return client
      .put(`/products/${id}/definition`, data)
      .then((res) => propOr({}, "data", res));
  },
  roles: ({ id }) => {
    return client
      .get(`/products/${id}/roles`)
      .then((res) => propOr({}, "data", res));
  },
  addRole: ({ id, data }) => {
    return client
      .post(`/products/${id}/roles`, data)
      .then((res) => propOr({}, "data", res));
  },
  editRole: ({ id, roleId, data }) => {
    return client
      .put(`/products/${id}/roles/${roleId}`, data)
      .then((res) => propOr({}, "data", res));
  },
  deleteRole: ({ id, roleId, data }) => {
    return client
      .delete(`/products/${id}/roles/${roleId}`, { data: data })
      .then((res) => propOr({}, "data", res));
  },
  editProperties: ({ id, data }) => {
    return restClient
      .put(`/products/${id}/properties`, data)
      .then((res) => propOr({}, "data", res));
  },
  log: ({ id }) => {
    return client
      .get(`/products/${id}/log`)
      .then((res) => propOr({}, "data", res));
  },
  logDetails: ({ id, logId, language }) => {
    return client
      .get(`/products/${id}/log/${logId}`, {
        params: {
          language,
        },
      })
      .then((res) => propOr({}, "data", res));
  },
};

export const release = {
  single: ({ id }) => {
    return client.get(`/releases/${id}`).then((res) => propOr({}, "data", res));
  },
  risks: ({ id }) => {
    return client
      .get(`/releases/${id}/risks`)
      .then((res) => propOr([], "data", res));
  },
  createRisk: ({ id, data }) => {
    return client
      .post(`/releases/${id}/risks`, data)
      .then((res) => propOr({}, "data", res));
  },
  editRisk: ({ id, riskId, data }) => {
    return restClient
      .put(`/releases/${id}/risks/${riskId}`, data)
      .then((res) => propOr({}, "data", res));
  },
  issues: ({ id }) => {
    return client
      .get(`/releases/${id}/issues`)
      .then((res) => propOr({}, "data", res));
  },
  createIssue: ({ id, data }) => {
    return client
      .post(`/releases/${id}/issues`, data)
      .then((res) => propOr({}, "data", res));
  },
  editIssue: ({ id, issueId, data }) => {
    return client
      .put(`/releases/${id}/issues/${issueId}`, data)
      .then((res) => propOr({}, "data", res));
  },
  report: {
    highlights: ({ id }) => {
      return client
        .get(`/releases/${id}/highlights`)
        .then((res) => propOr({}, "data", res));
    },
    createHighlightReport: ({ releaseId, ...data }) => {
      return client
        .post(`/releases/${releaseId}/progress/highlights`, data)
        .then((res) => propOr({}, "data", res));
    },
    editHighlightReport: ({ releaseId, reportId, ...data }) => {
      return client
        .post(`/releases/${releaseId}/progress/highlights/${reportId}`, data)
        .then((res) => propOr({}, "data", res));
    },
  },
  addFavorite: (data) => {
    return client.post("/favorites", {
      release: {
        id: data.id,
      },
    });
  },
  removeFavorite: ({ id }) => {
    return client.delete(`/favorites/${id}`);
  },
  log: ({ id }) => {
    return client
      .get(`/releases/${id}/log`)
      .then((res) => propOr({}, "data", res));
  },
  logDetails: ({ id, logId, language }) => {
    return client
      .get(`/releases/${id}/log/${logId}`, {
        params: {
          language,
        },
      })
      .then((res) => propOr({}, "data", res));
  },
};

export const programme = {
  add: (data) => {
    return client.post("/programmes", data);
  },
  single: ({ id }) => {
    return client
      .get(`/programmes/${id}`)
      .then((res) => propOr({}, "data", res));
  },
  updateDefinition: ({ id, data }) => {
    return client
      .put(`/programmes/${id}/definition`, data)
      .then((res) => propOr({}, "data", res));
  },
  list: () => {
    return client.get(`/programmes`).then((res) => propOr({}, "data", res));
  },
  report: {
    checkpoints: ({ id }) => {
      return client
        .get(`/programmes/${id}/checkpoints`)
        .then((res) => propOr({}, "data", res));
    },
    highlights: ({ id }) => {
      return client
        .get(`/programmes/${id}/highlights`)
        .then((res) => propOr({}, "data", res));
    },
  },
  risks: ({ id }) => {
    return client
      .get(`/programmes/${id}/risks`)
      .then((res) => propOr([], "data", res));
  },
  createRisk: ({ id, data }) => {
    return client
      .post(`/programmes/${id}/risks`, data)
      .then((res) => propOr({}, "data", res));
  },
  editRisk: ({ id, riskId, data }) => {
    return restClient
      .put(`/programmes/${id}/risks/${riskId}`, data)
      .then((res) => propOr({}, "data", res));
  },
  riskApproach: ({ id }) => {
    return client
      .get(`/programmes/${id}/risk/approach`)
      .then((res) => propOr([], "data", res));
  },
  updateRiskApproach: ({ programmeId, approach }) => {
    return client
      .put(`/programmes/${programmeId}/risk/approach`, { approach })
      .then((res) => propOr({}, "data", res));
  },
  issues: ({ id }) => {
    return client
      .get(`/programmes/${id}/issues`)
      .then((res) => propOr([], "data", res));
  },
  createIssue: ({ id, data }) => {
    return client
      .post(`/programmes/${id}/issues`, data)
      .then((res) => propOr({}, "data", res));
  },
  editIssue: ({ id, issueId, data }) => {
    return client
      .put(`/programmes/${id}/issues/${issueId}`, data)
      .then((res) => propOr({}, "data", res));
  },
  editVision: ({ id, data }) => {
    return restClient
      .patch(`/programmes/${id}`, { vision: data.vision })
      .then((res) => propOr({}, "data", res));
  },
  editTarget: ({ id, data }) => {
    return restClient
      .patch(`/programmes/${id}`, { target: data.target })
      .then((res) => propOr({}, "data", res));
  },
  addMeasurableBenefit: ({ id, data }) => {
    return restClient
      .post(`/programmes/${id}/measurable-benefits`, data)
      .then((res) => propOr({}, "data", res));
  },
  editMeasurableBenefit: ({ id, data }) => {
    return restClient
      .put(
        `/programmes/${id}/measurable-benefits/${data?.measurableBenefit?.id}`,
        data,
      )
      .then((res) => propOr({}, "data", res));
  },
  deleteMeasurableBenefit: ({ id, measurableBenefitId }) => {
    return restClient
      .delete(`/programmes/${id}/measurable-benefits/${measurableBenefitId}`)
      .then((res) => propOr({}, "data", res));
  },
  addOutcome: ({ id, data }) => {
    return restClient
      .post(`/programmes/${id}/outcomes`, data)
      .then((res) => propOr({}, "data", res));
  },
  editOutcome: ({ id, data }) => {
    return restClient
      .put(`/programmes/${id}/outcomes/${data?.outcome?.id}`, data)
      .then((res) => propOr({}, "data", res));
  },
  deleteOutcome: ({ id, outcomeId }) => {
    return restClient
      .delete(`/programmes/${id}/outcomes/${outcomeId}`)
      .then((res) => propOr({}, "data", res));
  },
  changeApproach: ({ id }) => {
    return client
      .get(`/programmes/${id}/change/approach`)
      .then((res) => propOr([], "data", res));
  },
  updateChangeApproach: ({ programmeId, approach }) => {
    return client
      .put(`/programmes/${programmeId}/change/approach`, { approach })
      .then((res) => propOr({}, "data", res));
  },
  createHighlightReport: ({ programmeId, ...data }) => {
    return client
      .post(`/programmes/${programmeId}/knowledge/highlights`, data)
      .then((res) => propOr({}, "data", res));
  },
  editHighlightReport: ({ programmeId, reportId, ...data }) => {
    return client
      .post(`/programmes/${programmeId}/knowledge/highlights/${reportId}`, data)
      .then((res) => propOr({}, "data", res));
  },
  roles: ({ id }) => {
    return client
      .get(`/programmes/${id}/roles`)
      .then((res) => propOr({}, "data", res));
  },
  addRole: ({ id, data }) => {
    return client
      .post(`/programmes/${id}/roles`, data)
      .then((res) => propOr({}, "data", res));
  },
  editRole: ({ id, roleId, data }) => {
    return client
      .put(`/programmes/${id}/roles/${roleId}`, data)
      .then((res) => propOr({}, "data", res));
  },
  deleteRole: ({ id, roleId, data }) => {
    return client
      .delete(`/programmes/${id}/roles/${roleId}`, { data: data })
      .then((res) => propOr({}, "data", res));
  },
  addFavorite: (data) => {
    return client.post("/favorites", {
      programme: {
        id: data.id,
      },
    });
  },
  removeFavorite: ({ id }) => {
    return client.delete(`/favorites/${id}`);
  },
  editProperties: ({ id, data }) => {
    return client
      .put(`/programmes/${id}/properties`, data)
      .then((res) => propOr({}, "data", res));
  },
  log: ({ id }) => {
    return client
      .get(`/programmes/${id}/log`)
      .then((res) => propOr({}, "data", res));
  },
  logDetails: ({ id, logId, language }) => {
    return client
      .get(`/programmes/${id}/log/${logId}`, {
        params: {
          language,
        },
      })
      .then((res) => propOr({}, "data", res));
  },
};
