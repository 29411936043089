import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Page } from "@/layout/Page";
import { Section } from "@/layout/Section";
import { useT } from "@/hooks/useT";
import { CustomAccordion, HeaderTypography } from "@/layout/CustomAccordion";
import {
  Typography,
  Stack,
  Button,
  IconButton,
  Paper,
  TextField,
  Box,
  MenuItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ButtonBase,
  Alert,
  AlertTitle,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EventIcon from "@mui/icons-material/Event";
import { useQ } from "@/hooks/useQ";
import { project, product, portfolio } from "@/queries";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import SlideshowOutlinedIcon from "@mui/icons-material/SlideshowOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import * as yup from "yup";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormTextField } from "@/base/form/mui/FormTextField";
import { FormSelect } from "@/base/form/mui/FormSelect";
import { FormRichTextField } from "@/base/form/mui/FormRichTextField";
import { FormDateRangePicker } from "@/base/form/mui/FormDateRangePicker";
import { useMutation } from "react-query";
import { useToast } from "@/hooks/useToast";
import { v4 as uuidv4 } from "uuid";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import { FormDataProvider } from "@/base/form/data-context/FormDataContext";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import Radio from "@mui/material/Radio";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ReportOffIcon from "@mui/icons-material/ReportOff";
import FlagIcon from "@mui/icons-material/Flag";
import EditIcon from "@mui/icons-material/Edit";
import {
  Document,
  Page as PDFPage,
  PDFDownloadLink,
  PDFViewer,
  View,
} from "@react-pdf/renderer";
import { PDFKit, styles } from "@/base/pdf";
import DownloadIcon from "@mui/icons-material/Download";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DatePretty } from "@/base/DatePretty";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { clientUrl } from "@/util/routing";
import { uris } from "@/config/nav";
import { FormMultiSelect } from "@/base/form/mui/FormMultiSelect";
import { stripHtml } from "@/util/stripHtml";
import { ProductsTable } from "@/pages/projects/components/ProductsTable";
import { useFlag } from "@/hooks/useFlag";
import { StepperSections } from "@/base/form/mui/StepperSections";
import { CustomDialog } from "@/layout/CustomDialog";
import { DeleteButton } from "@/base/DeleteButton";
import { StepperNavigator } from "@/base/form/mui/StepperNavigator";

const TableCell = ({ sx = {}, ...props }) => (
  <Typography variant="h4" flex={1} textAlign="center" sx={sx} {...props} />
);

const StageReleaseActiveDialog = ({
  portfolioId,
  isMakeReleaseActiveDialogOpen,
  setIsMakeReleaseActiveDialogOpen,
  onMakeReleaseActive,
}) => {
  const { t } = useT();
  const schema = yup.object({
    method: yup.string().required(),
    projectId: yup.string().when("method", {
      is: (method) => method === "project",
      then: () =>
        yup.string().required('projectId is required when method is "project"'),
      otherwise: () => yup.string().optional(),
    }),
  });
  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      method: "product",
      projectId: "",
    },
  });
  const { control, setValue, handleSubmit } = formData;
  const [method] = useWatch({
    control: control,
    name: ["method"],
  });

  const { data: portfolioData = {} } = useQ(`portfolio-${portfolioId}`, () =>
    portfolio.single({ id: portfolioId }),
  );

  const projectsOptions = useMemo(
    () =>
      (portfolioData?.items ?? [])
        .filter((item) => item.type === "project")
        .map((item) => ({
          label: item.name,
          value: item.id,
        })) ?? [],
    [portfolioData],
  );

  return (
    <Dialog
      open={isMakeReleaseActiveDialogOpen}
      onClose={() => {
        setIsMakeReleaseActiveDialogOpen(false);
      }}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "md",
        },
      }}
    >
      <FormDataProvider
        formKey="product.releaseActiveForm.fields"
        {...formData}
      >
        <form onSubmit={handleSubmit(onMakeReleaseActive)}>
          <DialogTitle>
            <Typography variant="h2">
              {t("product.releaseActiveForm.implementationMethod")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography>
                {t("product.releaseActiveForm.description")}
              </Typography>
              <Stack direction="row" spacing={2}>
                <ButtonBase
                  sx={{ flex: 1, textAlign: "left" }}
                  onClick={() => setValue("method", "product")}
                >
                  <Paper
                    sx={{
                      width: "100%",
                      height: "100%",
                      bgcolor: method === "product" ? "lightblue" : "inherit",
                    }}
                  >
                    <Stack spacing={2} p={2}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Radio checked={method === "product"} sx={{ p: 0 }} />
                        <Typography variant="h3" fontWeight="bold">
                          {t("project.plans.schedule.productManagement")}
                        </Typography>
                      </Stack>
                      <Typography>
                        {t("product.releaseActiveForm.help.useIf")}
                        <ul>
                          <li>{t("product.releaseActiveForm.help.team")}</li>
                          <li>
                            {t("product.releaseActiveForm.help.context.title")}
                            <ul>
                              <li>
                                {t(
                                  "product.releaseActiveForm.help.context.stakeholders",
                                )}
                              </li>
                              <li>
                                {t(
                                  "product.releaseActiveForm.help.context.interfaces",
                                )}
                              </li>
                              <li>
                                {t(
                                  "product.releaseActiveForm.help.context.competency",
                                )}
                              </li>
                            </ul>
                          </li>
                          <li>
                            {t(
                              "product.releaseActiveForm.help.certainty.title",
                            )}
                            <ul>
                              <li>
                                {t(
                                  "product.releaseActiveForm.help.certainty.clarity",
                                )}
                              </li>
                              <li>
                                {t(
                                  "product.releaseActiveForm.help.certainty.apatite",
                                )}
                              </li>
                              <li>
                                {t(
                                  "product.releaseActiveForm.help.certainty.technology",
                                )}
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </Typography>
                    </Stack>
                  </Paper>
                </ButtonBase>
                <ButtonBase
                  sx={{ flex: 1, textAlign: "left" }}
                  onClick={() => setValue("method", "project")}
                >
                  <Paper
                    sx={{
                      width: "100%",
                      height: "100%",
                      bgcolor: method === "project" ? "lightblue" : "inherit",
                    }}
                  >
                    <Stack spacing={2} p={2}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Radio checked={method === "project"} sx={{ p: 0 }} />
                        <Typography variant="h3" fontWeight="bold">
                          {t("project.plans.schedule.projectManagement")}
                        </Typography>
                      </Stack>
                      {method === "project" && (
                        <FormSelect
                          name="projectId"
                          options={projectsOptions}
                        />
                      )}
                      <Typography>
                        {t("project.plans.schedule.useWhen.title")}
                        <ul>
                          <li>
                            {t("project.plans.schedule.useWhen.team.title")}
                            <ul>
                              <li>
                                {t(
                                  "project.plans.schedule.useWhen.team.temporary",
                                )}
                              </li>
                              <li>
                                {t("project.plans.schedule.useWhen.team.large")}
                              </li>
                              <li>
                                {t(
                                  "project.plans.schedule.useWhen.team.spread",
                                )}
                              </li>
                            </ul>
                          </li>
                          <li>
                            {t("project.plans.schedule.useWhen.context.title")}
                            <ul>
                              <li>
                                {t(
                                  "project.plans.schedule.useWhen.context.stakeholders",
                                )}
                              </li>
                              <li>
                                {t(
                                  "project.plans.schedule.useWhen.context.interfaces",
                                )}
                              </li>
                              <li>
                                {t(
                                  "project.plans.schedule.useWhen.context.competancy",
                                )}
                              </li>
                            </ul>
                          </li>
                          <li>
                            {t(
                              "project.plans.schedule.useWhen.uncertainty.title",
                            )}
                            <ul>
                              <li>
                                {t(
                                  "project.plans.schedule.useWhen.uncertainty.scope",
                                )}
                              </li>
                              <li>
                                {t(
                                  "project.plans.schedule.useWhen.uncertainty.appetite",
                                )}
                              </li>
                              <li>
                                {t(
                                  "project.plans.schedule.useWhen.uncertainty.technology",
                                )}
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </Typography>
                    </Stack>
                  </Paper>
                </ButtonBase>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setIsMakeReleaseActiveDialogOpen(false);
              }}
            >
              {t("generic.button.cancel")}
            </Button>
            <Button variant="contained" color="primary" type="submit">
              {t("project.plans.schedule.details.makeReleaseActive")}
            </Button>
          </DialogActions>
        </form>
      </FormDataProvider>
    </Dialog>
  );
};

const StageRegisterActualsDialog = ({
  type,
  stage,
  isOpen,
  setIsOpen,
  onAssignActuals,
}) => {
  const { t } = useT();
  const schema = yup.object().shape({
    period: yup
      .object({
        from: yup.date().nullable().optional(),
        to: yup.date().nullable().optional(),
      })
      .optional(),
  });
  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      period: {
        from: stage?.period?.from ? moment(stage.period.from) : null,
        to: stage?.period?.to ? moment(stage.period.to) : null,
      },
      budget: stage?.budget ?? [],
    },
  });
  const { handleSubmit, control } = formData;
  const [budget] = useWatch({
    control,
    name: ["budget"],
  });
  const translationKey =
    type === "projects" ? "project.plans.stage" : "product.roadmap.release";
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      sx={{
        "& .MuiDialog-paper": {
          minWidth: "800px",
          maxWidth: "md",
        },
      }}
    >
      <FormDataProvider
        formKey="product.releaseActiveForm.fields"
        {...formData}
      >
        <form
          onSubmit={handleSubmit((data) =>
            onAssignActuals({
              period: {
                from: data.period.from
                  ? moment(data.period.from).format("YYYY-MM-DD")
                  : null,
                to: data.period.to
                  ? moment(data.period.to).format("YYYY-MM-DD")
                  : null,
              },
              cost: data.budget,
            }),
          )}
        >
          <DialogTitle>
            <Typography variant="h2">
              {t("project.plans.schedule.details.registerActuals")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Stack spacing="2px">
                <Typography>{t(translationKey + ".actualPeriod")}</Typography>
                <FormDateRangePicker
                  minName="period.from"
                  maxName="period.to"
                  label={(periodFrom, periodTo) => {
                    return (
                      <Stack direction="row" spacing={2} alignItems="center">
                        <EventIcon />
                        <Typography>{`${t(
                          translationKey + ".timePeriode.title",
                        )} ${
                          periodFrom && periodTo
                            ? `${moment(periodFrom).format(
                                "YYYY-MM-DD",
                              )} - ${moment(periodTo).format("YYYY-MM-DD")}`
                            : ""
                        }`}</Typography>
                        <EditOutlinedIcon />
                      </Stack>
                    );
                  }}
                />
              </Stack>

              <CustomAccordion
                header={{
                  sx: {
                    backgroundColor: "#F9FAFB",
                  },
                  columns: [
                    {
                      title: t(translationKey + ".costElement.title"),
                      flex: 1,
                    },
                    {
                      title: t("project.plans.schedule.headings.cost"),
                      flex: 1,
                    },
                    {
                      title: t("project.plans.schedule.headings.comments"),
                      flex: 1,
                    },
                  ],
                }}
                rows={budget?.map((item, index) => {
                  return {
                    expanded: false,
                    expandDisabled: true,
                    columns: [
                      {
                        title: {
                          hours: t("project.plans.schedule.headings.hours"),
                          expences: t(
                            "project.plans.schedule.headings.expenses",
                          ),
                          investment: t(
                            "project.plans.schedule.headings.investments",
                          ),
                          total: t("project.plans.schedule.headings.total"),
                        }[item?.id],
                        flex: 1,
                      },
                      {
                        content: (
                          <FormTextField
                            label=""
                            help=""
                            placeholder=""
                            name={`budget.${index}.cost`}
                          />
                        ),
                        flex: 1,
                      },
                      {
                        content: (
                          <FormTextField
                            label=""
                            help=""
                            placeholder=""
                            name={`budget.${index}.comment`}
                          />
                        ),
                        flex: 1,
                      },
                    ],
                  };
                })}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t("generic.button.cancel")}
            </Button>
            <Button variant="contained" color="primary" type="submit">
              {t("project.plans.stage.updateActual")}
            </Button>
          </DialogActions>
        </form>
      </FormDataProvider>
    </Dialog>
  );
};

const exceptionPlanLevels = (t) => [
  {
    value: "stage",
    label: t(
      "project.plans.requestExceptionApproval.fields.level.options.stage",
    ),
  },
  {
    value: "project",
    label: t(
      "project.plans.requestExceptionApproval.fields.level.options.project",
    ),
  },
];

const StageRequestExceptionApprovalDialog = ({
  exceptionReports,
  isOpen,
  setIsOpen,
  onSubmit,
  exceptionPlan,
}) => {
  const { t } = useT();
  const schema = yup.object().shape({
    exceptionReport: yup.string().optional(),
    summary: yup.string().required(),
    level: yup.string().required(),
  });
  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      exceptionReport: exceptionPlan?.exceptionReport ?? "-",
      level: exceptionPlan?.level ?? "stage",
      summary: exceptionPlan?.summary ?? "",
    },
  });
  const { handleSubmit, reset, control } = formData;

  const reportsOptions = useMemo(
    () =>
      [
        {
          value: "-",
          label: t(
            "project.plans.requestExceptionApproval.fields.exceptionReport.description",
          ),
        },
      ].concat(
        exceptionReports.map((report) => ({
          value: report.id,
          label: `${moment(report.date).format("YYYY-MM-DD")} ${
            report?.issue?.title
          }`,
        })),
      ),
    [exceptionReports],
  );

  useEffect(() => {
    if (exceptionPlan) {
      reset({
        exceptionReport: exceptionPlan?.exceptionReport ?? "-",
        level: exceptionPlan?.level ?? "stage",
        summary: exceptionPlan?.summary ?? "",
      });
    }
  }, [exceptionPlan]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      sx={{
        "& .MuiDialog-paper": {
          minWidth: "800px",
          maxWidth: "800px",
        },
      }}
    >
      <FormDataProvider
        formKey="project.plans.requestExceptionApproval.fields"
        {...formData}
      >
        <form
          onSubmit={handleSubmit(({ exceptionReport, ...data }) => {
            onSubmit({
              ...data,
              exceptionReport:
                exceptionReport === "-" ? undefined : exceptionReport,
            });
          })}
        >
          <DialogTitle>
            <Typography variant="h2">
              {t("project.plans.schedule.details.exceptionPlan")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2} sx={{ mt: 1 }}>
              <FormSelect name="level" options={exceptionPlanLevels(t)} />
              <FormSelect name="exceptionReport" options={reportsOptions} />
              <FormRichTextField name="summary" />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t("generic.button.cancel")}
            </Button>
            <Button variant="contained" color="primary" type="submit">
              {t(
                exceptionPlan
                  ? "generic.button.update"
                  : "generic.button.create",
              )}
            </Button>
          </DialogActions>
        </form>
      </FormDataProvider>
    </Dialog>
  );
};

export const StageDetailsPdf = ({ type, stage, projectData, productData }) => {
  const { t } = useT();
  const { H1, H2, H3, H4, BODY, HR, HTML } = PDFKit;

  const translationKey =
    type === "projects" ? "project.plans.stage" : "product.roadmap.release";

  return (
    <Document>
      <PDFPage size="A4" style={styles.page}>
        <H1>
          {type === "projects"
            ? t("project.plans.schedule.details.title")
            : t("product.plans.schedule.details.title")}{" "}
          - {stage?.name}
        </H1>
        <H2>
          {type === "projects" ? projectData?.name : productData?.name} -{" "}
          <DatePretty
            format={"YYYY-MM-DD"}
            date={moment(stage?.approved?.date)}
          />
        </H2>
        <View style={{ marginBottom: 10 }} />
        <HTML>
          {`<table>
              <tbody>
                ${[
                  ...[
                    "description",
                    "prerequisite",
                    "externalDependencies",
                    "assumptions",
                    "stageApproach",
                    "lessonsIncorporated",
                    "monitorAndControl",
                  ]
                    .filter((item) => !!stage?.[item])
                    .map(
                      (item) =>
                        `<tr>
                    <td style="padding: 8px; border: 1px solid #e5e7db; max-width: 150px;">${
                      {
                        description: t(translationKey + ".description.title"),
                        prerequisite: t(translationKey + ".prerequisite.title"),
                        externalDependencies: t(
                          translationKey + ".externalDependencies.title",
                        ),
                        assumptions: t(translationKey + ".assumptions.title"),
                        stageApproach: t(
                          translationKey + ".stageApproach.title",
                        ),
                        lessonsIncorporated: t(
                          translationKey + ".lessonsIncorporated.title",
                        ),
                        monitorAndControl: t(
                          translationKey + ".monitorAndControl.title",
                        ),
                      }[item]
                    }</td>
                    <td style="padding: 8px; border: 1px solid #e5e7db;">${
                      stage?.[item]
                    }</td>
                  </tr>`,
                    ),
                  (stage?.budget ?? []).some(
                    (budget) =>
                      budget?.cost || budget?.tolerance || budget?.comment,
                  )
                    ? `<tr>
                    <td style="padding: 8px; border: 1px solid #e5e7db; max-width: 150px;">${t(
                      "project.plans.schedule.details.budget",
                    )}</td>
                    <td style="padding: 8px; border: 1px solid #e5e7db;">
                      <table>
                      <thead>
                      <tr style="background-color: #f3f4f6; border: 1px solid #e5e7db;">
                        <td style="padding: 8px;">${t(
                          "project.plans.stage.costElement.title",
                        )}</td>
                        <td style="padding: 8px;">${t(
                          "project.plans.stage.cost.title",
                        )}</td>
                        <td style="padding: 8px;">${t(
                          "project.plans.schedule.headings.tolerance",
                        )}</td>
                        <td style="padding: 8px;">${t(
                          "project.plans.schedule.headings.comments",
                        )}</td>
                      </tr>
                      </thead>
                      <tbody>
                        ${stage?.budget
                          ?.map(
                            (item) => `
                              <tr style="border: 1px solid #e5e7db;">
                              <td style="padding: 8px;">${
                                {
                                  hours: t(
                                    "project.plans.schedule.headings.hours",
                                  ),
                                  expences: t(
                                    "project.plans.schedule.headings.expenses",
                                  ),
                                  investment: t(
                                    "project.plans.schedule.headings.investments",
                                  ),
                                  total: t(
                                    "project.plans.schedule.headings.total",
                                  ),
                                }[item?.id]
                              }</td>
                              <td style="padding: 8px;">${item?.cost ?? ""}</td>
                              <td style="padding: 8px;">${
                                item?.tolerance ?? ""
                              }</td>
                              <td style="padding: 8px;">${
                                item?.comment ?? ""
                              }</td>
                            </tr>`,
                          )
                          .join("")}
                      </tbody>
                      </table>
                      <p style="padding-top: 10px;">
                                ${t(
                                  "project.plans.schedule.details.costIsShownIn",
                                )}
                                :${" "}
                                ${
                                  type === "projects"
                                    ? projectData?.currency
                                    : productData?.currency
                                }
                      </p>
                    </tr>`
                    : null,
                  !!stage?.method
                    ? `<tr>
                    <td style="padding: 8px; border: 1px solid #e5e7db; max-width: 150px;">${t(
                      "project.plans.stage.method.title",
                    )}</td>
                    <td style="padding: 8px; border: 1px solid #e5e7db;">${
                      stage.method === "product"
                        ? "Product Management"
                        : "Project Management"
                    }</td>
                  </tr>`
                    : null,
                  `<tr>
                    <td style="padding: 8px; border: 1px solid #e5e7db; max-width: 150px;">${t(
                      "project.plans.schedule.details.schedule",
                    )}</td>
                    <td style="padding: 8px; border: 1px solid #e5e7db;">
                      <table>
                        <tbody>
                        <tr>
                          <td style="padding: 4px;">
                            ${t(
                              "project.plans.schedule.details.startDate",
                            )}:${" "}
                            ${stage?.time?.start}
                          </td>
                          <td style="padding: 4px;">
                            ${t(
                              "project.plans.schedule.details.endDate",
                            )}:${" "}
                            ${stage?.time?.end}
                          </td>
                          <td style="padding: 4px;">
                            ${t(
                              "project.plans.schedule.headings.tolerance",
                            )}:${" "}
                            ${stage?.timeTolerance ?? ""}${" "}
                            ${
                              stage?.timeToleranceUnit
                                ? t(
                                    "project.plans.stage.timeTolerance.units." +
                                      stage?.timeToleranceUnit,
                                  ).toLowerCase()
                                : ""
                            }
                          </td>
                          <td style="padding: 4px;">
                            ${
                              stage?.timeComments
                                ? `${t(
                                    "project.plans.schedule.details.comments",
                                  )}: ${stage?.timeComments}`
                                : ""
                            }
                          </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>`,
                ].join("")}
              </tbody>
            </table>`}
        </HTML>
      </PDFPage>
    </Document>
  );
};

export const StageDetails = ({
  type,
  projectData,
  productData,
  products,
  selectedProducts,
  stage,
  status,
  onEdit,
  onRequestApproval,
  onApprove,
  onRetract,
  onMakeReleaseActive,
  onAssignActuals,
  onRequestExceptionApproval,
  exceptionReports,
  onDelete,
  viewOnly = false,
  onCompleted,
}) => {
  const { t, i18n } = useT();
  const [selectedStageVersion, setSelectedStageVersion] = useState(stage);
  const [isMakeReleaseActiveDialogOpen, setIsMakeReleaseActiveDialogOpen] =
    useState(false);
  const [isRegisterActualsDialogOpen, setIsRegisterActualsDialogOpen] =
    useState(false);
  const [
    isRequestExceptionApprovalDialogOpen,
    setIsRequestExceptionApprovalDialogOpen,
  ] = useState(false);
  const useOptimizedReports = useFlag("project.optimize.reports");
  const [showPdf, setShowPdf] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const isMakeReleaseActiveEnabled = useMemo(() => {
    return (
      !!selectedStageVersion?.isRelease &&
      !selectedStageVersion?.active &&
      selectedStageVersion?.phase !== "Completed"
    );
  }, [selectedStageVersion]);

  const translationKey =
    type === "projects" ? "project.plans.stage" : "product.roadmap.release";

  const handleCreateEndStageReport = useCallback(() => {
    navigate(
      clientUrl(uris.project.create_progress, {
        type: "endStage",
        id: projectData.id,
      }),
    );
  }, [projectData]);

  const { data: highlights = [] } = useQ(
    `project-${projectData?.id}-report-highlights`,
    () => project.report.highlights({ id: projectData?.id }),
    {
      enabled: type === "projects" && !!projectData?.id && !useOptimizedReports,
    },
  );

  const { data: endStageReports = [] } = useQ(
    `project-${projectData?.id}-report-list`,
    () => project.report.list({ id: projectData?.id }),
    {
      enabled:
        type === "projects" && !!projectData?.id && !!useOptimizedReports,
    },
  );

  const endStageReport = useMemo(
    () =>
      (useOptimizedReports ? endStageReports : highlights).find(
        (report) =>
          report.type === "endStage" || report?.stage?.id === stage?.id,
      ),
    [highlights, endStageReports, useOptimizedReports, stage],
  );

  const hasHistory = useMemo(() => (stage?.history ?? []).length > 0, [stage]);

  const isSelectedStageVersionHistoric = useMemo(
    () => selectedStageVersion?.version !== stage?.version,
    [selectedStageVersion, stage],
  );

  const isSelectedStageVersionCurrent = useMemo(
    () => typeof selectedStageVersion?.version === "undefined",
    [selectedStageVersion],
  );

  const [openProductId, setOpenProductId] = useState(null);

  const multiLevelProducts = useMemo(() => {
    const deepSearch = (multiLevelProducts, products, position) => {
      for (let i = 0; i < products.length; i++) {
        const product = products[i];
        const currentPosition = [...position, i + 1];
        multiLevelProducts.push({
          ...product,
          position: currentPosition,
          children: product.products,
          isLeaf: product.parentId && !product?.products?.length,
        });
        if (product?.products?.length) {
          deepSearch(multiLevelProducts, product.products, currentPosition);
        }
      }
    };

    const multiLevelProducts = [];
    deepSearch(multiLevelProducts, products ?? [], []);
    return multiLevelProducts;
  }, [products]);

  useEffect(() => {
    setSelectedStageVersion(stage);
  }, [stage]);

  const completeStage = useMutation(product.completeRelease, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      onCompleted?.();
      toast.success("Release completed successfully");
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error completing release");
    },
  });

  return (
    <Paper>
      {!showPdf ? (
        <>
          <Box p={2}>
            {!viewOnly && (
              <Stack spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" spacing={1}>
                    <TextField
                      select
                      value={
                        hasHistory
                          ? isSelectedStageVersionCurrent
                            ? "current"
                            : selectedStageVersion.version
                          : "1"
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          height: "39px",
                        },
                      }}
                    >
                      <MenuItem value="1">
                        {t("project.plans.schedule.details.archive")}
                      </MenuItem>
                      {typeof stage?.version === "undefined" && (
                        <MenuItem
                          value="current"
                          onClick={() => setSelectedStageVersion(stage)}
                        >
                          {t("project.plans.schedule.details.current")}
                        </MenuItem>
                      )}
                      {(stage?.history ?? [])
                        .sort((a, b) => b.version - a.version)
                        .map((item) => (
                          <MenuItem
                            value={item.version}
                            sx={{
                              fontWeight:
                                item.version === selectedStageVersion?.version
                                  ? "bold"
                                  : "normal",
                            }}
                            onClick={() => setSelectedStageVersion(item)}
                          >
                            1.{item.version} (
                            {moment(item.approved?.date).format("YYYY-MM-DD")})
                          </MenuItem>
                        ))}
                    </TextField>
                    {!!endStageReport && (
                      <Button
                        variant="outlined"
                        startIcon={<SummarizeIcon />}
                        onClick={() => {
                          navigate(
                            clientUrl(uris.project.progressFull, {
                              id: projectData.id,
                              reportId: endStageReport.id,
                              type: "endStage",
                            }),
                          );
                        }}
                      >
                        {t("project.progress.endStage.title")}
                      </Button>
                    )}
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="text"
                      color="secondary"
                      startIcon={<FullscreenOutlinedIcon />}
                      disabled
                    >
                      {t("project.plans.schedule.details.views.full")}
                    </Button>
                    <Button
                      variant="text"
                      color="secondary"
                      startIcon={<SlideshowOutlinedIcon />}
                      disabled
                    >
                      {t("project.plans.schedule.details.views.slide")}
                    </Button>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="text"
                      color="secondary"
                      startIcon={<PictureAsPdfOutlinedIcon />}
                      onClick={() => setShowPdf(true)}
                    >
                      PDF
                    </Button>
                    <Button
                      variant="text"
                      color="secondary"
                      startIcon={<DescriptionOutlinedIcon />}
                      onClick={() => {
                        window.location.href = `/external/projects/${
                          projectData.id
                        }/plan/stages/${
                          stage.id
                        }?version=0&fullPage=true&docx=true&lang=${
                          i18n.language === "no" ? "norwegian" : "english"
                        }`;
                      }}
                    >
                      Word
                    </Button>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  {!!onDelete &&
                    stage?.active !== true &&
                    stage?.historic !== true &&
                    stage?.requestedTransitionTo !== true && (
                      <DeleteButton
                        onConfirm={() => onDelete({ id: stage.id })}
                        content={`Are you sure you want to delete the stage ${stage.name}?`}
                      />
                    )}
                  {stage?.phase === "Active" && (
                    <Button
                      variant="text"
                      color="secondary"
                      startIcon={<CheckIcon />}
                      onClick={() => {
                        completeStage.mutate({
                          productId: productData.id,
                          releaseId: stage.id,
                        });
                      }}
                      data-cy="complete-release-button"
                    >
                      {t("project.plans.schedule.details.completed")}
                    </Button>
                  )}
                  <Button
                    variant="text"
                    color="secondary"
                    startIcon={<EditOutlinedIcon />}
                    onClick={onEdit}
                    disabled={isSelectedStageVersionHistoric}
                    data-cy="edit-stage-button"
                  >
                    {t("project.plans.schedule.details.edit")}
                  </Button>
                  {status === "currentStage" && !projectData?.isClosing && (
                    <Button
                      variant="text"
                      color="secondary"
                      startIcon={<SummarizeIcon />}
                      onClick={handleCreateEndStageReport}
                      disabled={isSelectedStageVersionHistoric}
                    >
                      {t("project.plans.schedule.details.createEndStageReport")}
                    </Button>
                  )}
                </Stack>
                {status === "currentStage" &&
                  !stage?.approved &&
                  !(hasHistory && isSelectedStageVersionHistoric) && (
                    <Stack spacing={3} alignItems="center" sx={{ mt: 3 }}>
                      <Alert severity="error">
                        <AlertTitle>
                          {t("project.plans.schedule.details.warning")}
                        </AlertTitle>
                      </Alert>
                      {!projectData?.requestedStageException && (
                        <Box>
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<ReportOffIcon />}
                            onClick={() =>
                              setIsRequestExceptionApprovalDialogOpen(true)
                            }
                          >
                            {t(
                              "project.plans.schedule.details.requestExceptionApproval",
                            )}
                          </Button>
                        </Box>
                      )}
                    </Stack>
                  )}
              </Stack>
            )}
            <Typography
              variant="h1"
              textAlign="center"
              textTransform="uppercase"
              mt={4}
            >
              {type === "projects"
                ? t("project.plans.schedule.details.title")
                : t("product.plans.schedule.details.title")}{" "}
              - {selectedStageVersion?.name}
            </Typography>
            <Typography variant="h2" textAlign="center" mt={2}>
              {type === "projects" ? projectData?.name : productData?.name} -{" "}
              {moment(selectedStageVersion?.approved?.date).format(
                "YYYY-MM-DD",
              )}
            </Typography>
            {stage?.phase === "Completed" && (
              <Typography variant="h2" textAlign="center" mt={2}>
                {t("project.plans.schedule.details.statusCompleted")}
              </Typography>
            )}
            <Box
              sx={{
                backgroundColor: "#F9FAFB",
                py: 1,
                mt: 3,
                mb: 1,
              }}
            >
              <Typography textAlign="center">
                {t("project.plans.schedule.details.preview")}
              </Typography>
            </Box>
            <CustomAccordion
              rows={[
                ...[
                  "description",
                  "prerequisite",
                  "externalDependencies",
                  "assumptions",
                  "stageApproach",
                  "lessonsIncorporated",
                  "monitorAndControl",
                ].map((item) =>
                  selectedStageVersion?.[item]
                    ? {
                        expanded: false,
                        expandDisabled: true,
                        columns: [
                          {
                            title: {
                              description: t(
                                translationKey + ".description.title",
                              ),
                              prerequisite: t(
                                translationKey + ".prerequisite.title",
                              ),
                              externalDependencies: t(
                                translationKey + ".externalDependencies.title",
                              ),
                              assumptions: t(
                                translationKey + ".assumptions.title",
                              ),
                              stageApproach: t(
                                translationKey + ".stageApproach.title",
                              ),
                              lessonsIncorporated: t(
                                translationKey + ".lessonsIncorporated.title",
                              ),
                              monitorAndControl: t(
                                translationKey + ".monitorAndControl.title",
                              ),
                            }[item],
                            flex: 1,
                          },
                          {
                            content: (
                              <Typography
                                dangerouslySetInnerHTML={{
                                  __html: selectedStageVersion?.[item],
                                }}
                              />
                            ),
                            flex: 5,
                          },
                        ],
                      }
                    : null,
                ),
                (selectedStageVersion?.budget ?? []).some(
                  (budget) =>
                    budget?.cost || budget?.tolerance || budget?.comment,
                )
                  ? {
                      expandDisabled: true,
                      expanded: false,
                      columns: [
                        {
                          title: t("project.plans.schedule.details.budget"),
                          flex: 1,
                        },
                        {
                          content: (
                            <Box>
                              <CustomAccordion
                                header={{
                                  sx: {
                                    backgroundColor: "#F9FAFB",
                                  },
                                  columns: [
                                    {
                                      title: t(
                                        "project.plans.stage.costElement.title",
                                      ),
                                      flex: 1,
                                    },
                                    {
                                      title: t(
                                        "project.plans.stage.cost.title",
                                      ),
                                      flex: 1,
                                    },
                                    {
                                      title: t(
                                        "project.plans.schedule.headings.tolerance",
                                      ),
                                      flex: 1,
                                    },
                                    {
                                      title: t(
                                        "project.plans.schedule.headings.comments",
                                      ),
                                      flex: 1,
                                    },
                                  ],
                                }}
                                rows={selectedStageVersion?.budget?.map(
                                  (item) => ({
                                    expandDisabled: true,
                                    columns: [
                                      {
                                        title: {
                                          hours: t(
                                            "project.plans.schedule.headings.hours",
                                          ),
                                          expences: t(
                                            "project.plans.schedule.headings.expenses",
                                          ),
                                          investment: t(
                                            "project.plans.schedule.headings.investments",
                                          ),
                                          total: t(
                                            "project.plans.schedule.headings.total",
                                          ),
                                        }[item?.id],
                                        flex: 1,
                                      },
                                      {
                                        title: item?.cost,
                                        flex: 1,
                                      },
                                      {
                                        title: item?.tolerance,
                                        flex: 1,
                                      },
                                      {
                                        title: item?.comment,
                                        flex: 1,
                                      },
                                    ],
                                  }),
                                )}
                              />
                              <Typography mt={2}>
                                {t(
                                  "project.plans.schedule.details.costIsShownIn",
                                )}
                                :{" "}
                                {type === "projects"
                                  ? projectData?.currency
                                  : productData?.currency}
                              </Typography>
                            </Box>
                          ),
                          flex: 5,
                        },
                      ],
                    }
                  : null,
                !!selectedStageVersion?.method
                  ? {
                      expanded: false,
                      expandDisabled: true,
                      columns: [
                        {
                          title: t("project.plans.stage.method.title"),
                          flex: 1,
                        },
                        {
                          title:
                            selectedStageVersion.method === "product"
                              ? t("project.plans.schedule.productManagement")
                              : t("project.plans.schedule.projectManagement"),
                          flex: 5,
                        },
                      ],
                    }
                  : null,
                {
                  expanded: false,
                  expandDisabled: true,
                  columns: [
                    {
                      title: t("project.plans.schedule.details.schedule"),
                      flex: 1,
                    },
                    {
                      content: (
                        <Stack
                          direction={"row"}
                          justifyContent="space-between"
                          spacing={1}
                        >
                          <Typography>
                            {t("project.plans.schedule.details.startDate")}:{" "}
                            {selectedStageVersion?.time?.start}
                          </Typography>
                          <Typography>
                            {t("project.plans.schedule.details.endDate")}:{" "}
                            {selectedStageVersion?.time?.end}
                          </Typography>
                          <Typography>
                            {t("project.plans.schedule.headings.tolerance")}:{" "}
                            {selectedStageVersion?.timeTolerance}{" "}
                            {selectedStageVersion?.timeToleranceUnit
                              ? t(
                                  "project.plans.stage.timeTolerance.units." +
                                    selectedStageVersion?.timeToleranceUnit,
                                ).toLowerCase()
                              : null}
                          </Typography>
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: selectedStageVersion?.timeComments
                                ? `${t(
                                    "project.plans.schedule.details.comments",
                                  )}: ${selectedStageVersion?.timeComments}`
                                : "",
                            }}
                          />
                        </Stack>
                      ),
                      flex: 5,
                    },
                  ],
                },
              ]}
            />
            {isMakeReleaseActiveEnabled && !viewOnly && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 4 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<ToggleOnIcon />}
                  onClick={() => {
                    setIsMakeReleaseActiveDialogOpen((p) => !p);
                  }}
                >
                  {t("project.plans.schedule.details.makeReleaseActive")}
                </Button>
              </Stack>
            )}
            {!selectedStageVersion?.isRelease &&
              status === "nextStage" &&
              !viewOnly && (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mt: 4 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AssignmentTurnedInOutlinedIcon />}
                    onClick={() => onRequestApproval(stage?.id)}
                  >
                    {t("project.plans.schedule.details.requestApproval")}
                  </Button>
                </Stack>
              )}
            {!!stage?.historic && !viewOnly && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 4 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AppRegistrationIcon />}
                  onClick={() => setIsRegisterActualsDialogOpen(true)}
                >
                  {t("project.plans.schedule.details.registerActuals")}
                </Button>
              </Stack>
            )}
            {status === "pendingApproval" && !viewOnly && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 4 }}
                spacing={3}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<CancelPresentationIcon />}
                  onClick={() => onRetract(stage?.id)}
                >
                  {t("project.plans.schedule.details.retractApproval")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AssignmentTurnedInOutlinedIcon />}
                  onClick={() => onApprove(stage?.id)}
                >
                  {t("project.plans.schedule.details.approve")}
                </Button>
              </Stack>
            )}
            <Typography variant={"h2"} sx={{ mt: 3 }}>
              {t("project.plans.stage.scheduleComments.title")}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Typography
              sx={{ mt: 1 }}
              dangerouslySetInnerHTML={{
                __html: selectedStageVersion?.scheduleComments,
              }}
            />
            {type === "projects" && (
              <ProductsTable
                products={selectedProducts
                  .map((id) => multiLevelProducts.find((p) => p.id === id))
                  .filter((item) => !!item?.id)}
                openProductId={openProductId}
                handleRowClick={(item) =>
                  setOpenProductId(item.id === openProductId ? null : item.id)
                }
                onUpdate={() => refetchProducts()}
                id={null}
                scopes={[]}
                viewOnly
              />
            )}
            {!!selectedStageVersion?.approved?.exception && (
              <>
                <Typography textAlign="center" mt={4}>
                  Version 1.{selectedStageVersion?.version}: Automatically
                  approved with approval of exception
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html:
                        selectedStageVersion?.approved?.exception?.summary,
                    }}
                  />
                </Typography>
              </>
            )}
            {!!selectedStageVersion?.approved && (
              <Typography variant="h2" textAlign="center" mt={4}>
                {type === "projects"
                  ? `${t("project.plans.schedule.details.approvedBy")} ${
                      selectedStageVersion?.approved?.user?.name
                    }, ${moment(selectedStageVersion?.approved?.date).format(
                      "YYYY-MM-DD",
                    )}`
                  : `${t("project.plans.schedule.details.updatedOn")} ${moment(
                      selectedStageVersion?.approved?.date,
                    ).format("YYYY-MM-DD")}`}
              </Typography>
            )}
          </Box>
          {isMakeReleaseActiveEnabled &&
            !viewOnly &&
            !!productData?.portfolio?.id && (
              <StageReleaseActiveDialog
                portfolioId={productData?.portfolio?.id}
                isMakeReleaseActiveDialogOpen={isMakeReleaseActiveDialogOpen}
                setIsMakeReleaseActiveDialogOpen={
                  setIsMakeReleaseActiveDialogOpen
                }
                onMakeReleaseActive={({ method, projectId }) => {
                  setIsMakeReleaseActiveDialogOpen(false);
                  onMakeReleaseActive(stage?.id, method, projectId);
                }}
              />
            )}
          {!!stage?.historic && !viewOnly && (
            <StageRegisterActualsDialog
              type={type}
              stage={stage}
              isOpen={isRegisterActualsDialogOpen}
              setIsOpen={setIsRegisterActualsDialogOpen}
              onAssignActuals={({ cost, period }) => {
                setIsRegisterActualsDialogOpen(false);
                onAssignActuals(stage?.id, cost, period);
              }}
            />
          )}
          <StageRequestExceptionApprovalDialog
            exceptionReports={exceptionReports}
            isOpen={isRequestExceptionApprovalDialogOpen}
            setIsOpen={setIsRequestExceptionApprovalDialogOpen}
            onSubmit={(data) => {
              setIsRequestExceptionApprovalDialogOpen(false);
              onRequestExceptionApproval(data);
            }}
          />
        </>
      ) : (
        <Box p={2}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                onClick={() => setShowPdf(false)}
                startIcon={<ArrowBackIcon />}
              >
                Go Back
              </Button>
              <PDFDownloadLink
                document={
                  <StageDetailsPdf
                    type={type}
                    stage={selectedStageVersion}
                    projectData={projectData}
                    product={product}
                  />
                }
                fileName={`stagePlan_${selectedStageVersion?.name?.replace?.(
                  / /g,
                  "",
                )}_${moment(selectedStageVersion?.approved?.date).format(
                  "YYYY-MM-DD",
                )}`}
              >
                <Button variant="contained" startIcon={<DownloadIcon />}>
                  Download PDF
                </Button>
              </PDFDownloadLink>
            </Stack>
            <Box sx={{ height: "600px" }}>
              <PDFViewer
                style={{
                  width: "100%",
                  height: "100%",
                }}
                showToolbar={false}
              >
                <StageDetailsPdf
                  type={type}
                  stage={selectedStageVersion}
                  projectData={projectData}
                  productData={productData}
                />
              </PDFViewer>
            </Box>
          </Stack>
        </Box>
      )}
    </Paper>
  );
};

export const StageForm = ({
  type,
  stage,
  onSubmit,
  onCancel,
  open,
  products,
  refetchProducts,
}) => {
  const { t } = useT();
  const schema = yup.object().shape({
    id: yup.string().optional(),
    name: yup.string().required(),
    description: yup.string().optional(),
    prerequisite: yup.string().optional(),
    externalDependencies: yup.string().optional(),
    assumptions: yup.string().optional(),
    stageApproach: yup.string().optional(),
    lessonsIncorporated: yup.string().optional(),
    monitorAndControl: yup.string().optional(),
    period: yup
      .object({
        from: yup.date().nullable().optional(),
        to: yup.date().nullable().optional(),
      })
      .optional(),
    timeTolerance: yup.string().optional(),
    timeToleranceUnit: yup.string().optional(),
    timeComments: yup.string().optional(),
    budget: yup.array().of(
      yup.object({
        id: yup.string().required(),
        identifier: yup.string().optional(),
        cost: yup.string().optional(),
        tolerance: yup.string().optional(),
        comment: yup.string().optional(),
      }),
    ),
    scheduleComments: yup.string().optional(),
  });
  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: stage?.id ?? "",
      identifier: stage?.identifier ?? "",
      name: stage?.name ?? "",
      description: stage?.description ?? "",
      prerequisite: stage?.prerequisite ?? "",
      externalDependencies: stage?.externalDependencies ?? "",
      assumptions: stage?.assumptions ?? "",
      stageApproach: stage?.stageApproach ?? "",
      lessonsIncorporated: stage?.lessonsIncorporated ?? "",
      monitorAndControl: stage?.monitorAndControl ?? "",
      period: {
        from: stage?.time?.start ? moment(stage?.time?.start) : null,
        to: stage?.time?.end ? moment(stage?.time?.end) : null,
      },
      timeTolerance: stage?.timeTolerance ?? "",
      timeToleranceUnit: stage?.timeToleranceUnit ?? "",
      timeComments: stage?.timeComments ?? "",
      budget: ["hours", "expences", "investment", "total"].map((item) => ({
        id: item,
        cost: stage?.budget?.find((i) => i.id === item)?.cost ?? "",
        tolerance: stage?.budget?.find((i) => i.id === item)?.tolerance ?? "",
        comment: stage?.budget?.find((i) => i.id === item)?.comment ?? "",
      })),
      scheduleComments: stage?.scheduleComments ?? "",
      products: stage?.products ?? [],
    },
  });
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
    setValue,
  } = formData;
  const [name, selectedProducts, budget] = useWatch({
    control,
    name: ["name", "products", "budget"],
  });
  const [activeStep, setActiveStep] = useState(0);
  const nextStep = useRef(null);
  const stageSteps = useMemo(() => {
    return type === "projects"
      ? ["description", "context", "time", "cost", "schedule"]
      : ["description", "time", "cost", "schedule"];
  }, [type]);
  const isCreating = !stage;
  const [openProductId, setOpenProductId] = useState(null);
  const usePbs = useFlag("plans.pbs");

  const productContainsSubProduct = useCallback((product, subProductId) => {
    return (
      product.children &&
      product.children.some(
        (item) =>
          item.id === subProductId ||
          productContainsSubProduct(item, subProductId),
      )
    );
  }, []);

  const multiLevelProducts = useMemo(() => {
    const deepSearch = (multiLevelProducts, products, position) => {
      for (let i = 0; i < products.length; i++) {
        const product = products[i];
        const currentPosition = [...position, i + 1];
        multiLevelProducts.push({
          ...product,
          position: currentPosition,
          children: product.products,
          isLeaf: product.parentId && !product?.products?.length,
        });
        if (product?.products?.length) {
          deepSearch(multiLevelProducts, product.products, currentPosition);
        }
      }
    };

    const multiLevelProducts = [];
    deepSearch(multiLevelProducts, products ?? [], []);
    return multiLevelProducts;
  }, [products]);

  const productsOptions = useMemo(
    () =>
      multiLevelProducts.map((product) => ({
        id: product.id,
        value: `${product.position.join(".")} ${stripHtml(product.title)}`,
        disabled: !product.isLeaf,
      })),
    [multiLevelProducts],
  );

  const getStepIndex = (step) => {
    return type === "projects"
      ? ["description", "context", "time", "cost", "schedule"].indexOf(step)
      : ["description", "time", "cost", "schedule"].indexOf(step);
  };

  const formKey =
    type === "projects" ? "project.plans.stage" : "product.roadmap.release";

  useEffect(() => {
    reset({
      id: stage?.id ?? "",
      identifier: stage?.identifier ?? "",
      name: stage?.name ?? "",
      description: stage?.description ?? "",
      prerequisite: stage?.prerequisite ?? "",
      externalDependencies: stage?.externalDependencies ?? "",
      assumptions: stage?.assumptions ?? "",
      stageApproach: stage?.stageApproach ?? "",
      lessonsIncorporated: stage?.lessonsIncorporated ?? "",
      monitorAndControl: stage?.monitorAndControl ?? "",
      period: {
        from: stage?.time?.start ? moment(stage?.time?.start) : null,
        to: stage?.time?.end ? moment(stage?.time?.end) : null,
      },
      timeTolerance: stage?.timeTolerance ?? "",
      timeToleranceUnit: stage?.timeToleranceUnit ?? "",
      timeComments: stage?.timeComments ?? "",
      budget: ["hours", "expences", "investment", "total"].map((item) => ({
        id: item,
        cost: stage?.budget?.find((i) => i.id === item)?.cost ?? "",
        tolerance: stage?.budget?.find((i) => i.id === item)?.tolerance ?? "",
        comment: stage?.budget?.find((i) => i.id === item)?.comment ?? "",
      })),
      scheduleComments: stage?.scheduleComments ?? "",
      products: stage?.products ?? [],
    });
    const name = stage?.name;
    if (!name?.length) {
      setActiveStep(0);
    } else if (nextStep.current !== null) {
      setActiveStep(nextStep.current);
      nextStep.current = null;
    } else {
      setActiveStep(1);
    }
  }, [reset, open, stage, stageSteps]);

  const selectedProductsNotLeaves = useMemo(
    () =>
      multiLevelProducts.filter(
        (product) => selectedProducts.includes(product.id) && !product.isLeaf,
      ),
    [selectedProducts, multiLevelProducts],
  );

  const submitButtonLabel = useMemo(() => {
    if (isCreating) {
      return t(formKey + ".createStage");
    } else if (!isDirty) {
      if (activeStep === 0) {
        return !name?.length ? false : t("generic.stepper.next");
      } else if (activeStep === stageSteps.length - 1) {
        return false;
      }
      return t("generic.stepper.next");
    } else {
      if (activeStep === 0) {
        return !name?.length
          ? t("risk.registerForm.buttons.update")
          : t("risk.registerForm.buttons.updateAndNext");
      } else if (activeStep === stageSteps.length - 1) {
        return t("risk.registerForm.buttons.update");
      } else {
        return t("risk.registerForm.buttons.updateAndNext");
      }
    }
  }, [isCreating, activeStep, isDirty, name]);

  const triggerSubmit = useMemo(
    () =>
      handleSubmit(async (data) => {
        if (isDirty) {
          await onSubmit(data);
          nextStep.current = Math.min(stageSteps.length - 1, activeStep + 1);
        } else {
          setActiveStep((a) => a + 1);
        }
      }),
    [handleSubmit, onSubmit, stageSteps, reset, isDirty, activeStep],
  );

  const showPreviousButton = useMemo(() => {
    return !isCreating && activeStep > 0;
  }, [isCreating, activeStep]);

  return (
    <CustomDialog
      isOpen={open}
      onClose={onCancel}
      scrollDependencyArray={[activeStep]}
    >
      <FormDataProvider formKey={formKey} {...formData}>
        <form onSubmit={triggerSubmit}>
          <StepperSections
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={stageSteps}
            formatLabel={(step) => t(`${formKey}.${step}.title`)}
          />
          <Box sx={{ mt: 4 }}>
            {activeStep === getStepIndex("description") && (
              <Stack spacing={2}>
                <Stack spacing={2} direction={"row"}>
                  <FormTextField name="name" required />
                  {type === "products" ? (
                    <FormTextField name="identifier" />
                  ) : null}
                </Stack>
                <FormRichTextField name="description" />
                <FormRichTextField name="prerequisite" />
              </Stack>
            )}
            {activeStep === getStepIndex("context") && (
              <Stack spacing={2}>
                <FormRichTextField name="externalDependencies" />
                <FormRichTextField name="assumptions" />
                <FormRichTextField name="stageApproach" />
                <FormRichTextField name="lessonsIncorporated" />
                <FormRichTextField name="monitorAndControl" />
              </Stack>
            )}
            {activeStep === getStepIndex("time") && (
              <Stack spacing={2}>
                <FormDateRangePicker
                  minName="period.from"
                  maxName="period.to"
                  label={(periodFrom, periodTo) => {
                    return (
                      <Stack direction="row" spacing={2} alignItems="center">
                        <EventIcon />
                        <Typography>{`${t(
                          "project.plans.stage.timePeriode.title",
                        )} ${
                          periodFrom && periodTo
                            ? `${moment(periodFrom).format(
                                "YYYY-MM-DD",
                              )} - ${moment(periodTo).format("YYYY-MM-DD")}`
                            : ""
                        }`}</Typography>
                        <EditOutlinedIcon />
                      </Stack>
                    );
                  }}
                />
                <Stack direction="row" spacing={2}>
                  <FormTextField name="timeTolerance" />
                  <FormSelect
                    name="timeToleranceUnit"
                    options={[
                      {
                        value: "days",
                        label: t(
                          "project.plans.stage.timeTolerance.units.days",
                        ),
                      },
                      {
                        value: "weeks",
                        label: t(
                          "project.plans.stage.timeTolerance.units.weeks",
                        ),
                      },
                      {
                        value: "months",
                        label: t(
                          "project.plans.stage.timeTolerance.units.months",
                        ),
                      },
                    ]}
                  />
                </Stack>
                <FormRichTextField name="timeComments" />
              </Stack>
            )}
            {activeStep === getStepIndex("cost") && (
              <CustomAccordion
                header={{
                  sx: {
                    backgroundColor: "#F9FAFB",
                  },
                  columns: [
                    {
                      title: t("project.plans.stage.costElement.title"),
                      flex: 1,
                    },
                    {
                      title: t("project.plans.schedule.headings.cost"),
                      flex: 1,
                    },
                    {
                      title: t("project.plans.schedule.headings.tolerance"),
                      flex: 1,
                    },
                    {
                      title: t("project.plans.schedule.headings.comments"),
                      flex: 1,
                    },
                  ],
                }}
                rows={budget?.map((item, index) => {
                  return {
                    expanded: false,
                    expandDisabled: true,
                    columns: [
                      {
                        title: {
                          hours: t("project.plans.schedule.headings.hours"),
                          expences: t(
                            "project.plans.schedule.headings.expenses",
                          ),
                          investment: t(
                            "project.plans.schedule.headings.investments",
                          ),
                          total: t("project.plans.schedule.headings.total"),
                        }[item?.id],
                        flex: 1,
                      },
                      {
                        content: (
                          <FormTextField
                            fieldKey="costTable.cost"
                            name={`budget.${index}.cost`}
                          />
                        ),
                        flex: 1,
                      },
                      {
                        content: (
                          <FormTextField
                            fieldKey="costTable.tolerance"
                            name={`budget.${index}.tolerance`}
                          />
                        ),
                        flex: 1,
                      },
                      {
                        content: (
                          <FormTextField
                            fieldKey="costTable.comment"
                            name={`budget.${index}.comment`}
                          />
                        ),
                        flex: 1,
                      },
                    ],
                  };
                })}
              />
            )}
            {activeStep === getStepIndex("schedule") && (
              <Stack spacing={2}>
                <FormRichTextField name="scheduleComments" />
                {type === "projects" && usePbs && (
                  <>
                    <FormMultiSelect
                      name="products"
                      options={productsOptions}
                    />
                    {!!selectedProductsNotLeaves.length && (
                      <Alert severity="warning">
                        The selected products are not leaves:{" "}
                        {selectedProductsNotLeaves
                          .map((p) => stripHtml(p.title))
                          .join(", ")}
                      </Alert>
                    )}
                    <ProductsTable
                      handleDrop={({ source, target }) => {
                        const startIndex = source.index;
                        const endIndex = target.index;
                        if (startIndex !== endIndex) {
                          const newProducts = [...selectedProducts];
                          newProducts[startIndex] = newProducts[endIndex];
                          newProducts[endIndex] = selectedProducts[startIndex];
                          setValue("products", newProducts, {
                            shouldDirty: true,
                          });
                        }
                      }}
                      products={selectedProducts
                        .map((id) =>
                          multiLevelProducts.find((p) => p.id === id),
                        )
                        .filter((item) => !!item?.id)}
                      openProductId={openProductId}
                      handleRowClick={(item) =>
                        setOpenProductId(
                          item.id === openProductId ? null : item.id,
                        )
                      }
                      onUpdate={() => refetchProducts()}
                      id={null}
                      scopes={[]}
                    />
                  </>
                )}
              </Stack>
            )}
          </Box>
          <StepperNavigator
            showPreviousButton={showPreviousButton}
            setActiveStep={setActiveStep}
            onCancel={onCancel}
            isDirty={isDirty}
            submitButtonLabel={submitButtonLabel}
          />
        </form>
      </FormDataProvider>
    </CustomDialog>
  );
};

export const StagePlanForm = ({
  showSum = true,
  showContent = true,
  historicProjectPlan,
  viewOnly = false,
}) => {
  const { t } = useT();
  const { id = "", stageId = "" } = useParams();
  const navigate = useNavigate();
  const loc = useLocation();
  const match = matchPath({ path: "/:type/:id", end: false }, loc.pathname);
  const type = match?.params?.type;
  const { data: projectData = {}, refetch: refetchProject } = useQ(
    `project-${id}`,
    () => project.single({ id }),
    {
      enabled: type === "projects",
    },
  );
  const { data: productData = {}, refetch: refetchProduct } = useQ(
    `product-${id}`,
    () => product.single({ id }),
    {
      enabled: type === "products",
    },
  );
  const { data: projectPlanData = {}, refetch: refetchProjectPlans } = useQ(
    `project-${id}-plans`,
    () => project.plans({ id }),
    {
      enabled: type === "projects",
    },
  );
  const { data: productPlanData = {}, refetch: refetchProductPlans } = useQ(
    `product-${id}-plans`,
    () => product.releases({ id }),
    {
      enabled: type === "products",
    },
  );
  const { data: products = [], refetch: refetchProducts } = useQ(
    `project-${id}-products`,
    () => project.products({ id }),
    {
      enabled: type === "projects",
    },
  );

  let stages =
    type === "projects"
      ? projectPlanData.stages ?? []
      : productPlanData.stages ?? [];
  if (historicProjectPlan !== undefined) stages = historicProjectPlan;
  const currentStage =
    type === "projects" ? projectData.stage ?? null : productData.stage ?? null;

  const [showNewItemForm, setShowNewItemForm] = useState(false);
  const [stageOpen, setStageOpen] = useState(stages.map(() => false));
  const [editing, setEditing] = useState(stages.map(() => false));

  const toast = useToast();

  const [newStageId, setNewStageId] = useState(null);

  const addStage = useMutation(
    type === "projects" ? project.addStage : product.addRelease,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        toast.success("Stage created successfully");
        setShowNewItemForm(false);
        if (type === "projects") {
          refetchProjectPlans();
        } else {
          refetchProductPlans();
        }
      },
      onError: (error) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error("Error creating stage");
      },
    },
  );

  const updateStage = useMutation(
    type === "projects" ? project.updateStage : product.updateRelease,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        toast.success("Stage updated successfully");
        if (type === "projects") {
          refetchProjectPlans();
        } else {
          refetchProductPlans();
        }
      },
      onError: (error) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error("Error updating stage");
      },
    },
  );

  const deleteStage = useMutation(
    type === "projects" ? project.deleteStage : product.deleteRelease,
    {
      onSuccess: (data) => {
        if (type === "projects") {
          refetchProjectPlans();
        } else {
          refetchProductPlans();
        }
        console.log("Received data: " + JSON.stringify(data));
        toast.success("Deleted successfully");
      },
      onError: (error) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error("Error deleting");
      },
    },
  );

  const stageAction = useMutation(
    type === "projects" ? project.stageAction : product.updateRelease,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        if (type === "projects") {
          refetchProjectPlans();
          refetchProject();
        } else {
          refetchProductPlans();
          refetchProduct();
        }
      },
      onError: (error) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error("Error updating stage");
      },
    },
  );

  const startReleaseWithMethod = useMutation(product.startReleaseWithMethod, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      refetchProductPlans();
      refetchProduct();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error starting release");
    },
  });

  const stageAssignActuals = useMutation(
    type === "projects"
      ? project.stageAssignActuals
      : product.stageAssignActuals,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        toast.success("Actuals assigned successfully");
        if (type === "projects") {
          refetchProjectPlans();
          refetchProject();
        } else {
          refetchProductPlans();
          refetchProduct();
        }
      },
      onError: (error) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error("Error assigning actuals");
      },
    },
  );

  const requestExceptionApproval = useMutation(
    type === "projects" ? project.requestExceptionApproval : () => null, // product.requestExceptionApproval,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        toast.success("Exception approval requested successfully");
        if (type === "projects") {
          refetchProjectPlans();
          refetchProject();
        } else {
          refetchProductPlans();
          refetchProduct();
        }
      },
      onError: (error) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error("Error requesting exception approval");
      },
    },
  );

  const updateExceptionPlan = useMutation(
    type === "projects" ? project.updateExceptionPlan : () => null, // product.updateExceptionPlan,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        toast.success("Exception plan updated successfully");
        if (type === "projects") {
          refetchProjectPlans();
          refetchProject();
        } else {
          refetchProductPlans();
          refetchProduct();
        }
      },
      onError: (error) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error("Error updating exception plan");
      },
    },
  );

  const approveException = useMutation(
    type === "projects" ? project.approveException : () => null,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        toast.success("Exception approved successfully");
        if (type === "projects") {
          refetchProjectPlans();
          refetchProject();
        } else {
          refetchProductPlans();
          refetchProduct();
        }
      },
      onError: (error) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error("Error approving exception");
      },
    },
  );

  const handleUpdateStage = useCallback(
    async (data) => {
      console.log("Updating stage with data: " + JSON.stringify(data));
      await updateStage.mutate({
        [type === "projects" ? "projectId" : "productId"]: id,
        [type === "projects" ? "stage" : "release"]: {
          ...stages.find((s) => s?.id === data?.id),
          ...data,
          period: data.period.from
            ? `${moment(data.period.from).format("YYYY-MM-DD")} to ${moment(
                data.period.to,
              ).format("YYYY-MM-DD")}`
            : "",
        },
      });
    },
    [id],
  );

  const handleCreateStage = useCallback(
    async (data) => {
      const stageId = uuidv4();
      setNewStageId(stageId);
      await addStage.mutate(
        {
          [type === "projects" ? "projectId" : "productId"]: id,
          [type === "projects" ? "stage" : "release"]: {
            ...data,
            period: data.period.from
              ? `${moment(data.period.from).format("YYYY-MM-DD")} to ${moment(
                  data.period.to,
                ).format("YYYY-MM-DD")}`
              : "",
            id: stageId,
            _progress: "stageDescription",
          },
        },
        {
          onSuccess: () => {
            handleOpenStage(stageId);
          },
        },
      );
    },
    [id],
  );

  useEffect(() => {
    if (newStageId) {
      setEditing(stages.map((s) => newStageId === s.id));
      setNewStageId(null);
    }
  }, [stages, newStageId]);

  const handleDeleteStage = useCallback(
    async (data) => {
      await deleteStage.mutate({
        [type === "projects" ? "projectId" : "productId"]: id,
        [type === "projects" ? "stageId" : "releaseId"]: data.id,
      });
    },
    [id],
  );

  const nextStage = useMemo(() => {
    let nextStage = null;
    if (stages.length > 0) {
      let currentIndex =
        stages.findIndex((s) => s?.id === currentStage?.id) ?? -1;
      if (currentIndex + 1 < stages.length) {
        nextStage = stages[currentIndex + 1];
      } else {
        // var closeProject = true;
      }
    }
    return nextStage;
  }, [stages, currentStage]);

  const requestedStageTransition = useMemo(
    () => (type === "projects" ? projectData.requestedStageTransition : null),
    [projectData, type],
  );

  const handleRequestApproval = useCallback(
    async (stageId) => {
      if (type === "projects") {
        await stageAction.mutate({
          projectId: id,
          stageId,
          action: "requestApproval",
        });
        toast.success("Stage approval requested successfully");
      }
    },
    [id, nextStage],
  );

  const handleApprove = useCallback(
    async (stageId) => {
      if (type === "projects") {
        await stageAction.mutate({
          projectId: id,
          stageId,
          action: "approve",
        });
        toast.success("Stage approved successfully");
      }
    },
    [id, nextStage],
  );

  const handleRetract = useCallback(
    async (stageId) => {
      if (type === "projects") {
        await stageAction.mutate({
          projectId: id,
          stageId,
          action: "retract",
        });
        toast.success("Stage retracted successfully");
      }
    },
    [id, nextStage],
  );

  const handleMakeReleaseActive = useCallback(
    async (releaseId, method, projectId) => {
      await startReleaseWithMethod.mutate({
        productId: id,
        releaseId,
        method,
        projectId,
      });
      toast.success("Release activated successfully");
    },
    [id, nextStage],
  );

  const handleStageAssignActuals = useCallback(
    async (stageId, cost, period) => {
      await stageAssignActuals.mutate({
        productId: id,
        projectId: id,
        stageId,
        cost,
        period,
      });
    },
    [id, nextStage],
  );

  const handleRequestExceptionApproval = useCallback(
    async (data) => {
      await requestExceptionApproval.mutate({
        productId: id,
        projectId: id,
        ...data,
      });
    },
    [id, nextStage],
  );

  const handleUpdateExceptionPlan = useCallback(
    async (data) => {
      await updateExceptionPlan.mutate({
        productId: id,
        projectId: id,
        exceptionPlanId: projectData?.requestedStageException?.id,
        ...data,
      });
    },
    [id, projectData],
  );

  const handleApproveException = useCallback(async () => {
    await approveException.mutate({
      id: projectData?.requestedStageException?.id,
      productId: id,
      projectId: id,
      stageId: projectData?.requestedStageException?.stage?.id,
    });
  }, [id, projectData]);

  function intValue(value) {
    var intValue = parseFloat(value);
    return isNaN(intValue) ? 0 : intValue;
  }

  var minDate =
    stages !== undefined
      ? stages.map((stage) => stage?.time?.start).sort()[0]
      : "";
  var maxDate =
    stages !== undefined
      ? stages
          .map((stage) => stage?.time?.end)
          .sort()
          .reverse()[0]
      : "";
  var sumTimeTolerance =
    stages !== undefined
      ? stages.reduce(
          (a, b) =>
            a +
            intValue(
              b?.time?.tolerance?.toString().includes(" months")
                ? b?.time?.tolerance?.toString().replace(" months", "") * 21.5
                : b?.time?.tolerance?.toString().includes(" weeks")
                  ? b?.time?.tolerance?.toString().replace(" weeks", "") * 5
                  : b?.time?.tolerance?.toString().replace(" days", ""),
            ),
          0,
        )
      : "";
  var sumHours =
    stages !== undefined
      ? stages.reduce((a, b) => a + intValue(b?.cost.hours), 0)
      : 0;
  var sumExpences =
    stages !== undefined
      ? stages.reduce((a, b) => a + intValue(b?.cost.expences), 0)
      : 0;
  var sumInvestment =
    stages !== undefined
      ? stages.reduce((a, b) => a + intValue(b?.cost.investment), 0)
      : 0;
  var sumCost =
    stages !== undefined
      ? stages.reduce((a, b) => a + intValue(b?.cost.sum), 0)
      : 0;
  var sumCostTolerance =
    stages !== undefined
      ? stages.reduce((a, b) => a + intValue(b?.cost.tolerance), 0)
      : 0;

  const sumStage = {
    name:
      type === "projects"
        ? t("project.plans.schedule.details.total.project")
        : t("project.plans.schedule.details.total.product"),
    time: {
      start: minDate,
      end: maxDate,
      tolerance:
        sumTimeTolerance <= 5
          ? sumTimeTolerance
          : sumTimeTolerance <= 21
            ? sumTimeTolerance / 5 + " weeks"
            : (sumTimeTolerance / 21.5).toFixed(1) + " months",
    },
    cost: {
      expences: sumExpences,
      hours: sumHours,
      investment: sumInvestment,
      sum: sumCost,
      tolerance: sumCostTolerance,
    },
  };

  const stagesWithSum = [...stages, sumStage];

  const [
    isRequestExceptionApprovalDialogOpen,
    setIsRequestExceptionApprovalDialogOpen,
  ] = useState(false);

  const { data: issues = [] } = useQ(
    `project-${projectData?.id}-issues`,
    () => project.issues({ id: projectData?.id }),
    { enabled: type === "projects" },
  );

  const exceptionReports = useMemo(
    () =>
      (type === "projects" ? issues : [])
        .flatMap((i) => i.reports)
        .filter((r) => !!r && r?.exception)
        .sort((a, b) => new Date(b.date) - new Date(a.date)),
    [type, issues],
  );

  const handleOpenStage = useCallback(
    (newStageId) => {
      if (type === "projects") {
        if (newStageId === stageId) {
          navigate(
            clientUrl(uris.project.plans, {
              id,
            }),
          );
        } else {
          navigate(
            clientUrl(uris.project.plansStageDetails, {
              id,
              stageId: newStageId,
            }),
          );
        }
      } else {
        if (newStageId === stageId) {
          navigate(
            clientUrl(uris.product.roadmap, {
              id,
            }),
          );
        } else {
          navigate(
            clientUrl(uris.product.roadmapReleaseDetails, {
              id,
              stageId: newStageId,
            }),
          );
        }
      }
    },
    [navigate, id, type, stageId],
  );

  return (
    <>
      {showContent ? (
        <Stack spacing={3} sx={{ mb: 2 }}>
          {!!projectData?.requestedStageException && (
            <Stack spacing={2}>
              <Stack direction="row" spacing={1} alignItems="center">
                <FlagIcon />
                <Typography>
                  {t("project.plans.schedule.details.exceptionPlan")} (
                  {projectData?.requestedStageException?.level === "stage" ? (
                    <>
                      {t("project.plans.schedule.headings.stage")}:{" "}
                      {
                        stages.find(
                          (s) =>
                            s?.id ===
                            projectData?.requestedStageException?.stage?.id,
                        )?.name
                      }
                    </>
                  ) : (
                    t("project.plans.schedule.headings.project")
                  )}
                  )
                </Typography>
                <IconButton
                  onClick={() => setIsRequestExceptionApprovalDialogOpen(true)}
                >
                  <EditIcon />
                </IconButton>
              </Stack>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: projectData?.requestedStageException?.summary,
                }}
              />
              {!projectData?.requestedStageException?.exceptionReport?.id && (
                <Alert severity="error">
                  <AlertTitle>
                    {t("project.plans.schedule.details.missingReport")}
                  </AlertTitle>
                </Alert>
              )}
              <Stack direction="row" justifyContent="flex-start">
                <Button variant="contained" onClick={handleApproveException}>
                  {t("project.plans.approve")}
                </Button>
              </Stack>
            </Stack>
          )}
          <Stack direction="row" justifyContent="space-between" mb={1}>
            {!viewOnly && (
              <>
                <Typography variant="h6">
                  {type === "projects"
                    ? t("project.plans.type.project")
                    : t("project.plans.type.product")}
                </Typography>

                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => setShowNewItemForm((n) => !n)}
                  data-cy="add-stage-button"
                >
                  {t(
                    type === "projects"
                      ? "project.plans.stage.addStage"
                      : "product.roadmap.release.addStage",
                  )}
                </Button>
              </>
            )}
          </Stack>
        </Stack>
      ) : null}
      <CustomAccordion
        tableView
        header={{
          columns: [
            {
              title:
                type === "projects"
                  ? t("project.plans.schedule.headings.stage")
                  : t("project.plans.schedule.headings.release"),
              flex: 2,
            },
            {
              content: (
                <Stack spacing={1}>
                  <HeaderTypography>
                    {t("project.plans.schedule.headings.time")}
                  </HeaderTypography>
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    spacing={1}
                  >
                    <HeaderTypography flex={1}>
                      {t("project.plans.schedule.headings.start")}
                    </HeaderTypography>
                    <HeaderTypography flex={1} textAlign={"center"}>
                      {t("project.plans.schedule.headings.end")}
                    </HeaderTypography>
                    <HeaderTypography flex={1} textAlign="right">
                      {t("project.plans.schedule.headings.tolerance")}
                    </HeaderTypography>
                  </Stack>
                </Stack>
              ),
              flex: 3,
            },
            {
              content: (
                <Stack spacing={1}>
                  <HeaderTypography>
                    {t("project.plans.schedule.headings.cost")}
                    {projectData?.currency ? (
                      <span> ({projectData.currency})</span>
                    ) : null}
                  </HeaderTypography>
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    spacing={1}
                  >
                    <HeaderTypography flex={1} textAlign="left">
                      {t("project.plans.schedule.headings.hours")}
                    </HeaderTypography>
                    <HeaderTypography flex={1} textAlign="center">
                      {t("project.plans.schedule.headings.expenses")}
                    </HeaderTypography>
                    <HeaderTypography flex={1} textAlign="center">
                      {t("project.plans.schedule.headings.investments")}
                    </HeaderTypography>
                    <HeaderTypography flex={1} textAlign="center">
                      {t("project.plans.schedule.headings.sum")}
                    </HeaderTypography>
                    <HeaderTypography flex={1} textAlign="right">
                      {t("project.plans.schedule.headings.tolerance")}
                    </HeaderTypography>
                  </Stack>
                </Stack>
              ),
              flex: 5,
            },
          ],
          details: (
            <StageForm
              type={type}
              stage={null}
              open={showNewItemForm}
              onCancel={() => setShowNewItemForm(false)}
              onSubmit={handleCreateStage}
              products={products}
              refetchProducts={refetchProducts}
            />
          ),
          expanded: false,
        }}
        rows={(showSum ? stagesWithSum : stages).map((stage, index) => {
          const isStageOld =
            typeof stage?.historic !== "undefined" &&
            stage?.historic &&
            typeof stage?.active !== "undefined" &&
            !stage?.active;
          const isCurrentStage =
            type === "projects"
              ? projectData?.stage?.id === stage?.id
              : stage?.phase === "Active";

          const pendingApproval =
            requestedStageTransition?.stage?.id === stage?.id;
          const isNextStage = nextStage?.id === stage?.id;
          const status =
            pendingApproval && stage?.id !== undefined
              ? "pendingApproval"
              : isNextStage
                ? "nextStage"
                : isCurrentStage
                  ? "currentStage"
                  : undefined;
          const requestedTransitionTo =
            stage?.requestedTransitionTo && !stage?.actuals;
          const requestedException = stage?.requestedException;
          const isWaitingForExceptionApproval =
            !stage?.approved && (stage?.history ?? []).length > 0;

          return {
            expanded: stage?.id === stageId,
            expandDisabled: !showContent || !stage?.id,
            onClick: () =>
              showContent && stage?.id !== undefined
                ? handleOpenStage(stage?.id)
                : null,
            columns: [
              {
                content: (
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    spacing={2}
                  >
                    <Typography variant="h4">{stage?.name}</Typography>
                    {stage?.actuals && showContent && (
                      <CheckIcon color="success" />
                    )}
                    {((status === "pendingApproval" && showContent) ||
                      requestedTransitionTo) && <SportsScoreIcon />}
                    {!isWaitingForExceptionApproval &&
                      isCurrentStage &&
                      requestedException && <FlagIcon />}
                    {isCurrentStage && isWaitingForExceptionApproval && (
                      <WarningAmberIcon />
                    )}
                  </Stack>
                ),
                flex: 2,
              },
              {
                content: (
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    spacing={1}
                  >
                    <Typography variant="h4" flex={1}>
                      {stage?.time.start}
                    </Typography>
                    <Typography variant="h4" flex={1} textAlign="center">
                      {stage?.time.end}
                    </Typography>
                    {(stage?.timeTolerance &&
                      stage?.timeTolerance !== "" &&
                      stage?.timeTolerance !== "0") ||
                    (stage?.time?.tolerance &&
                      stage?.time?.tolerance !== " days" &&
                      stage?.time?.tolerance !== " weeks" &&
                      stage?.time?.tolerance !== " months" &&
                      stage?.time?.tolerance !== "0 days" &&
                      stage?.time?.tolerance !== "0 weeks" &&
                      stage?.time?.tolerance !== "0 months") ? (
                      <Typography variant="h4" flex={1} textAlign="right">
                        {stage?.timeTolerance
                          ? stage?.timeTolerance
                          : stage?.time?.tolerance
                            ? stage?.time?.tolerance
                                .toString()
                                .replace(" days", "")
                                .replace(" weeks", "")
                                .replace(" months", "")
                            : ""}{" "}
                        {stage?.timeToleranceUnit
                          ? t(
                              "project.plans.stage.timeTolerance.units." +
                                stage?.timeToleranceUnit,
                            ).toLowerCase()
                          : stage?.time?.tolerance &&
                              stage?.time?.tolerance
                                ?.toString()
                                .includes("weeks")
                            ? t(
                                "project.plans.stage.timeTolerance.units.weeks",
                              ).toLowerCase()
                            : stage?.time?.tolerance &&
                                stage?.time?.tolerance
                                  ?.toString()
                                  .includes("months")
                              ? t(
                                  "project.plans.stage.timeTolerance.units.months",
                                ).toLowerCase()
                              : stage?.time?.tolerance !== undefined
                                ? t(
                                    "project.plans.stage.timeTolerance.units.days",
                                  ).toLowerCase()
                                : ""}
                      </Typography>
                    ) : (
                      <Typography variant="h4" flex={1} textAlign="right" />
                    )}
                  </Stack>
                ),
                flex: 3,
              },
              {
                content: (
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    spacing={1}
                  >
                    <TableCell textAlign="left">{stage?.cost?.hours}</TableCell>
                    <TableCell>{stage?.cost?.expences}</TableCell>
                    <TableCell>{stage?.cost?.investment}</TableCell>
                    <TableCell>{stage?.cost?.sum}</TableCell>
                    <TableCell textAlign="right">
                      {stage?.cost?.tolerance}
                    </TableCell>
                  </Stack>
                ),
                flex: 5,
              },
            ],
            sx: {
              "& > .MuiAccordionSummary-root *": {
                color: isStageOld
                  ? "rgb(213, 213, 213) !important"
                  : "rgb(117, 117, 117) !important",
                fontStyle: isStageOld ? "italic" : "none",
                fontWeight: isCurrentStage ? "bold" : "normal",
              },
              ...(stage?.id === stageId ? { backgroundColor: "#ccc" } : {}),
            },
            details: showContent ? (
              <>
                {editing[index] && (
                  <StageForm
                    type={type}
                    stage={stage}
                    onCancel={() =>
                      setEditing((editing) => {
                        const newEditing = [...editing];
                        newEditing[index] = false;
                        return newEditing;
                      })
                    }
                    onSubmit={handleUpdateStage}
                    open={editing[index]}
                    products={products}
                    refetchProducts={refetchProducts}
                  />
                )}
                <StageDetails
                  type={type}
                  projectData={projectData}
                  productData={productData}
                  products={products}
                  selectedProducts={stage?.products ?? []}
                  stage={stage}
                  status={status}
                  onEdit={() =>
                    setEditing((editing) => {
                      const newEditing = [...editing];
                      newEditing[index] = true;
                      return newEditing;
                    })
                  }
                  onRequestApproval={handleRequestApproval}
                  onApprove={handleApprove}
                  onRetract={handleRetract}
                  onMakeReleaseActive={handleMakeReleaseActive}
                  onAssignActuals={handleStageAssignActuals}
                  onRequestExceptionApproval={handleRequestExceptionApproval}
                  viewOnly={viewOnly}
                  exceptionReports={exceptionReports}
                  onDelete={handleDeleteStage}
                  onCompleted={() => {
                    if (type === "projects") {
                      refetchProjectPlans();
                    } else {
                      refetchProductPlans();
                    }
                  }}
                />
              </>
            ) : null,
          };
        })}
      />
      <StageRequestExceptionApprovalDialog
        exceptionReports={exceptionReports}
        exceptionPlan={
          projectData?.requestedStageException
            ? {
                level: projectData.requestedStageException.level,
                summary: projectData.requestedStageException.summary,
                exceptionReport:
                  projectData.requestedStageException?.exceptionReport?.id,
              }
            : null
        }
        isOpen={isRequestExceptionApprovalDialogOpen}
        setIsOpen={setIsRequestExceptionApprovalDialogOpen}
        onSubmit={(data) => {
          setIsRequestExceptionApprovalDialogOpen(false);
          handleUpdateExceptionPlan(data);
        }}
      />
    </>
  );
};

export const Plans = () => {
  const { t } = useT();
  const loc = useLocation();
  const match = matchPath({ path: "/:type/:id", end: false }, loc.pathname);
  const type = match?.params?.type;

  return (
    <Page
      isLoading={false}
      title={
        type === "projects"
          ? t("project.plans.title")
          : t("product.roadmap.title")
      }
    >
      <Section
        title={
          type === "projects"
            ? t("project.plans.title")
            : t("product.roadmap.title")
        }
      >
        <StagePlanForm />
      </Section>
    </Page>
  );
};
