import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../layout/Page";
import { Section } from "../../layout/Section";
import { useT } from "../../hooks/useT";
import { portfolio, product } from "../../queries";
import { has, isNil, path, prop, propOr } from "ramda";
import { uris } from "../../config/nav";
import { clientUrl } from "../../util/routing";
import { SummaryCard } from "../../base/project/SummaryCard";
import { DataList } from "../../base/DataList";
import { BlockChart } from "../../base/charts/BlockChart";
import { useQ } from "../../hooks/useQ";
import { TableStat } from "../../base/TableStat";
import { InitiativeLink } from "../../base/InitiativeLink";
import { useNavigate } from "react-router-dom";

import {
  threatMatrix,
  opportunityMatrix,
  issueMatrix,
} from "../../base/risk/Risk";

import { Grid, Stack, Typography, Button, IconButton } from "@mui/material";
import { HtmlReadMoreTypography } from "../../base/typography/HtmlReadMoreTypography";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { CustomAccordion } from "../../layout/CustomAccordion";
import { StatusSummaryCard } from "../../base/StatusSummaryCard";
import { RiskThreatsBlockChart } from "@/pages/projects/components/RiskThreatsBlockChart";
import { IssuesBlockChart } from "@/pages/projects/components/IssuesBlockChart";
import { useFlag } from "@/hooks/useFlag";
import { useMutation } from "react-query";
import { useToast } from "@/hooks/useToast";

function level2AndAbove(level) {
  return isNil(level) || level >= 2;
}

const InfoSummaryCard = ({ data }) => {
  console.log({ data });
  const { identifier, executive } = data?.attributes ?? {};
  const { t } = useT();
  return (
    <SummaryCard>
      <Typography variant="h2" textAlign="center" mb={1}>
        {t("product.keyInformation.title")}
      </Typography>
      <DataList
        items={[
          {
            label: t("product.keyInformation.identifier"),
            value: identifier,
          },
          {
            label: t("product.keyInformation.executive"),
            value: executive,
          },
        ].filter((item) => !isNil(item.value))}
      />
    </SummaryCard>
  );
};

const RiskAndIssue = ({ productId, risks, issues }) => {
  const { t } = useT();

  return (
    <SummaryCard>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <Typography variant="h2" textAlign="center">
            {t("project.risksAndIssues.threats")}
          </Typography>
          <RiskThreatsBlockChart
            id={productId}
            type="product"
            threats={threatMatrix(risks)}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Typography variant="h2" textAlign="center">
            {t("project.risksAndIssues.opportunities")}
          </Typography>
          <BlockChart
            data={opportunityMatrix(risks)}
            xLabel={t("project.risksAndIssues.impact")}
            yLabel={t("project.risksAndIssues.probability")}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Typography variant="h2" textAlign="center">
            {t("project.risksAndIssues.issues")}
          </Typography>
          <IssuesBlockChart
            id={productId}
            type="product"
            issues={issueMatrix(issues)}
          />
        </Grid>
      </Grid>
    </SummaryCard>
  );
};

const mapTableChildren = (data) => {
  return data.map(({ items, ...rest }) => {
    return { ...rest, items, children: items };
  });
};

function onlyActiveChildren(item) {
  return {
    ...item,
    items: isNil(item.items)
      ? item.items
      : item.items.filter((child) => child.isStarted),
  };
}

export const Product = () => {
  const { id = "" } = useParams();
  const { t } = useT();
  const navigate = useNavigate();
  const useFavorite = useFlag("favorites.add");
  const toast = useToast();

  const {
    isLoading,
    data = {},
    refetch,
  } = useQ(`product-${id}`, () => product.single({ id }));

  const { isLoading: isLoadingPortfolio, data: portfolioData = {} } = useQ(
    `portfolio-${id}`,
    () => portfolio.single({ id: data?.portfolio?.id }),
    {
      enabled: !!data?.portfolio?.id,
    },
  );
  const { items = [] } = portfolioData;

  const { roadmap, background, stage, name } = data;

  const releases = useMemo(
    () =>
      mapTableChildren(
        items
          .filter((item) => item.isStarted === true)
          .filter((item) => (roadmap || []).map((r) => r.id).includes(item.id))
          .map((item) => onlyActiveChildren(item)),
      ),
    [items],
  );

  const [isFavorite, setFavorite] = useState(false);

  const addFavorite = useMutation(product.addFavorite, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Product added to favorites");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error adding product to favorites");
    },
  });

  const removeFavorite = useMutation(product.removeFavorite, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Product removed from favorites");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error removing product from favorites");
    },
  });

  const handleFavoriteToggle = useCallback(() => {
    if (useFavorite) {
      if (isFavorite) {
        removeFavorite.mutate({ id });
      } else {
        addFavorite.mutate({ id });
      }
    } else {
      setFavorite(!isFavorite);
    }
  }, [isFavorite, useFavorite, addFavorite, removeFavorite, id]);

  useEffect(() => {
    if (!useFavorite) {
      return;
    }
    const isFavorite = data?.isFavorite;
    setFavorite(isFavorite);
  }, [data?.isFavorite, useFavorite]);

  return (
    <Page isLoading={isLoading} title={name}>
      <Section
        title={
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h1">{name}</Typography>
            <IconButton onClick={handleFavoriteToggle}>
              {isFavorite ? <StarOutlinedIcon /> : <StarBorderOutlinedIcon />}
            </IconButton>
          </Stack>
        }
      >
        <Stack spacing={5}>
          <HtmlReadMoreTypography>{background}</HtmlReadMoreTypography>
          <Stack alignItems="center">
            <Button
              variant="contained"
              onClick={() =>
                navigate(clientUrl(uris.product.editDefinition, { id }))
              }
            >
              {t("project.overview.goto_edit_definition")}
            </Button>
          </Stack>
          <Stack direction="row" spacing={6} px={6}>
            <StatusSummaryCard data={data} type={"products"} />
            <InfoSummaryCard data={data} />
          </Stack>

          <CustomAccordion
            tableView
            header={{
              columns: [
                {
                  title: t("generic.titles.name"),
                  flex: 3,
                },
                {
                  title: t("generic.titles.status"),
                  width: 70,
                },
                {
                  title: t("portfolio.priority.table.part"),
                  flex: 3,
                },
                ...["benefits", "time", "cost", "quality", "scope", "risk"].map(
                  (name) => ({
                    title: t("generic.status." + name),
                    width: 70,
                    sx: { textAlign: "center" },
                  }),
                ),
              ],
            }}
            rows={(releases ?? []).map((data, index) => {
              const { type, id, name, level, part } = data;
              return {
                id,
                expanded: false,
                expandDisabled: true,
                columns: [
                  {
                    content: (
                      <InitiativeLink
                        type={type}
                        id={id}
                        title={name}
                        level={level}
                      />
                    ),
                    flex: 3,
                  },
                  {
                    content: <TableStat data={data} name={null} hide={null} />,
                    width: 70,
                  },
                  {
                    title: part,
                    flex: 3,
                  },
                  ...[
                    "benefits",
                    "time",
                    "cost",
                    "quality",
                    "scope",
                    "risk",
                  ].map((name) => ({
                    content: <TableStat data={data} name={name} />,
                    width: 70,
                    sx: { textAlign: "center" },
                  })),
                ],
              };
            })}
          />
          {level2AndAbove(data.level) ? (
            <RiskAndIssue
              productId={id}
              risks={data.risks}
              issues={data.issues}
            />
          ) : null}
          <CustomAccordion
            tableView
            header={{
              columns: [
                {
                  flex: 1,
                  title: t("generic.titles.name"),
                },
                {
                  flex: 1,
                  title: t("product.roadmap.release.method.title"),
                },
                {
                  flex: 1,
                  title: "Status",
                },
                {
                  flex: 2,
                  content: (
                    <Stack alignItems="center" spacing={1}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        {t("product.roadmap.release.schedule.title")}
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography textAlign="center" flex={1}>
                          {t("project.plans.schedule.details.startDate")}
                        </Typography>
                        <Typography textAlign="center" flex={1}>
                          {t("project.plans.schedule.details.endDate")}
                        </Typography>
                      </Stack>
                    </Stack>
                  ),
                },
              ],
            }}
            rows={roadmap
              ?.slice()
              .reverse()
              .map((item) => ({
                expanded: false,
                expandDisabled: true,
                clickable: true,
                onClick: () => {
                  navigate(
                    clientUrl(uris.release.single, {
                      id: item.id,
                    }),
                  );
                },
                columns: [
                  {
                    flex: 1,
                    title: item.name,
                  },
                  {
                    flex: 1,
                    title:
                      item.type !== "project" ? (
                        t("project.plans.schedule.productManagement")
                      ) : (
                        <Stack
                          direction="column"
                          spacing={2}
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Typography flex={1}>
                            {t("project.plans.schedule.projectManagement")}
                          </Typography>
                          <Typography flex={1}>{item.projectName}</Typography>
                        </Stack>
                      ),
                  },
                  {
                    flex: 1,
                    title:
                      item.state === "Completed"
                        ? t("project.plans.schedule.status.completed")
                        : t("project.plans.schedule.status.running"),
                  },
                  {
                    flex: 2,
                    title: item.duration.start,
                    content: (
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography textAlign="center" flex={1}>
                          {item.duration.start}
                        </Typography>
                        <Typography textAlign="center" flex={1}>
                          {item.duration.end}
                        </Typography>
                      </Stack>
                    ),
                    sx: {
                      textAlign: "center",
                    },
                  },
                ],
              }))}
          />
        </Stack>
      </Section>
    </Page>
  );
};

/**** Translation keys for dynamic Headings
 *
 * Activities:

 t('project.overview.activities.appointExecutiveAndPM.title');
 t('project.overview.activities.captureLessons.title');
 t('project.overview.activities.appointManagementTeam.title');
 t('project.overview.activities.prepareOutlineBusinessCase.title');
 t('project.overview.activities.defineProject.title');
 t('project.overview.activities.describeProjectProduct.title');
 t('project.overview.activities.selectApproach.title');
 t('project.overview.activities.planInitiation.title');
 t('project.overview.activities.assembleBrief.title');
 t('project.overview.activities.prepareRiskManagementApproach.title');
 t('project.overview.activities.prepareQualityManagementApproach.title');
 t('project.overview.activities.prepareConfigurationManagementApproach.title');
 t('project.overview.activities.prepareCommunicationManagementApproach.title');
 t('project.overview.activities.setupProjectControls.title');
 t('project.overview.activities.createProjectPlan.title');
 t('project.overview.activities.refineBusinessCase.title');
 t('project.overview.activities.defineProject.title');
 t('project.overview.activities.describeProjectProduct.title');
 t('project.overview.activities.assemblePID.title');
 t('project.overview.activities.createLessons.title');
 t('project.overview.activities.createEndProjectReport.title');
 t('project.overview.activities.closeRiskRegister.title');
 t('project.overview.activities.closeIssueRegister.title');
 t('project.overview.activities.recommendClosure.title');
 t('project.overview.activities.sponsoringTheProgramme.title');
 t('project.overview.activities.confirmProgrammeMandate.title');
 t('project.overview.activities.appointSROAndProgrammeBoard.title');
 t('project.overview.activities.produceProgrammeBrief.title');
 t('project.overview.activities.developProgrammePreparationPlan.title');
 t('project.overview.activities.independentReview.title');
 t('project.overview.activities.approvalToProceed.title');
 t('project.overview.activities.establishInfrastructure.title');
 t('project.overview.activities.establishTeam.title');
 t('project.overview.activities.indentifyStakeholders.title');
 t('project.overview.activities.refineVision.title');
 t('project.overview.activities.developBlueprint.title');
 t('project.overview.activities.developBenefitProfiles.title');
 t('project.overview.activities.modelBenefits.title');
 t('project.overview.activities.validateBenefits.title');
 t('project.overview.activities.designProjectDossier.title');
 t('project.overview.activities.identifyTranches.title');
 t('project.overview.activities.designProgrammeOrganization.title');
 t('project.overview.activities.developGovernanceArrangements.title');
 t('project.overview.activities.developProgrammePlan.title');
 t('project.overview.activities.confirmBusinessCase.title');
 t('project.overview.activities.consolitateProgrammeDefinition.title');
 t('project.overview.activities.prepareFirstTranche.title');
 t('project.overview.activities.approvalToProceed.title');
 t('project.overview.activities.empatize.title');
 t('project.overview.activities.define.title');
 t('project.overview.activities.ideate.title');
 t('project.overview.activities.prototype.title');
 t('project.overview.activities.test.title');

 * Activities, Help texts:

 t('project.overview.activities.appointExecutiveAndPM.help');
 t('project.overview.activities.captureLessons.help');
 t('project.overview.activities.appointManagementTeam.help');
 t('project.overview.activities.prepareOutlineBusinessCase.help');
 t('project.overview.activities.defineProject.help');
 t('project.overview.activities.describeProjectProduct.help');
 t('project.overview.activities.selectApproach.help');
 t('project.overview.activities.planInitiation.help');
 t('project.overview.activities.assembleBrief.help');
 t('project.overview.activities.prepareRiskManagementApproach.help');
 t('project.overview.activities.prepareQualityManagementApproach.help');
 t('project.overview.activities.prepareConfigurationManagementApproach.help');
 t('project.overview.activities.prepareCommunicationManagementApproach.help');
 t('project.overview.activities.setupProjectControls.help');
 t('project.overview.activities.createProjectPlan.help');
 t('project.overview.activities.refineBusinessCase.help');
 t('project.overview.activities.defineProject.help');
 t('project.overview.activities.describeProjectProduct.help');
 t('project.overview.activities.assemblePID.help');
 t('project.overview.activities.createLessons.help');
 t('project.overview.activities.createEndProjectReport.help');
 t('project.overview.activities.closeRiskRegister.help');
 t('project.overview.activities.closeIssueRegister.help');
 t('project.overview.activities.recommendClosure.help');
 t('project.overview.activities.sponsoringTheProgramme.help');
 t('project.overview.activities.confirmProgrammeMandate.help');
 t('project.overview.activities.appointSROAndProgrammeBoard.help');
 t('project.overview.activities.produceProgrammeBrief.help');
 t('project.overview.activities.developProgrammePreparationPlan.help');
 t('project.overview.activities.independentReview.help');
 t('project.overview.activities.approvalToProceed.help');
 t('project.overview.activities.establishInfrastructure.help');
 t('project.overview.activities.establishTeam.help');
 t('project.overview.activities.indentifyStakeholders.help');
 t('project.overview.activities.refineVision.help');
 t('project.overview.activities.developBlueprint.help');
 t('project.overview.activities.developBenefitProfiles.help');
 t('project.overview.activities.modelBenefits.help');
 t('project.overview.activities.validateBenefits.help');
 t('project.overview.activities.designProjectDossier.help');
 t('project.overview.activities.identifyTranches.help');
 t('project.overview.activities.designProgrammeOrganization.help');
 t('project.overview.activities.developGovernanceArrangements.help');
 t('project.overview.activities.developProgrammePlan.help');
 t('project.overview.activities.confirmBusinessCase.help');
 t('project.overview.activities.consolitateProgrammeDefinition.help');
 t('project.overview.activities.prepareFirstTranche.help');
 t('project.overview.activities.approvalToProceed.help');
 t('project.overview.activities.empatize.help');
 t('project.overview.activities.define.help');
 t('project.overview.activities.ideate.help');
 t('project.overview.activities.prototype.help');
 t('project.overview.activities.test.help');


 * Tasks

 t('project.overview.tasks.approveProjectBrief.title');
 t('project.overview.tasks.approvePID.title');
 t('project.overview.tasks.closeProject.title');

 */
