import React from "react";
import { useNavigate } from "react-router-dom";
import { isNil } from "ramda";
import { Icon } from "./Icon";
import { useStyleParams } from "../hooks/useStyleParams";

export const Button = ({
  children,
  to,
  icon,
  onClick,
  title,
  type = "button",
  variant = "primary",
  disabled = false,
  disabledTitle = "",
  ...rest
}) => {
  const { className, style } = useStyleParams(rest);

  const navigate = useNavigate();
  let handler = onClick;
  if (isNil(onClick) && !isNil(to)) {
    handler = () => navigate(to);
  }

  const t = isNil(title) ? children : title;

  return (
    <button
      disabled={disabled}
      title={disabled ? disabledTitle : t}
      type={type}
      onClick={handler}
      className={`btn btn-${variant} ${className}`}
      style={style}
    >
      {!isNil(icon) && <Icon i={icon} />}
      {children}
    </button>
  );
};

export const PrimaryButton = ({ variant, children, ...args }) => {
  return (
    <Button variant={"primary"} {...args}>
      {children}
    </Button>
  );
};

export const SecondaryButton = ({ variant, children, ...args }) => {
  return (
    <Button variant={"secondary"} {...args}>
      {children}
    </Button>
  );
};
