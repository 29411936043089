import React from "react";
import { useParams, useMatch } from "react-router-dom";
import { Page } from "@/layout/Page";
import { Section } from "@/layout/Section";
import { useT } from "@/hooks/useT";
import { useQ } from "@/hooks/useQ";
import { project, programme, product } from "@/queries";
import { OrganizationCategory } from "./components/OrganizationCategory";
import { isNil } from "ramda";
import { uris } from "@/config/nav";
import { clientUrl } from "@/util/routing";
import { Typography } from "@mui/material";
import { HtmlTypography } from "@/base/typography/HtmlTypography";

const defaultOrganization = {
  board: [],
  assurance: [
    //    {name:'Chris D’Espositon',role:['Executive', 'Development'], email:'chris.depositon@partners.com'},
    //    {name:'Jennifer Dillonn',role:['Supplier Assurance', 'Deputy to Senior Supplier','Deputy to Senior Supplier',], email:'jennifer.dillonn@partners.com'}
  ],
  management: [],
};

const sectionHelp = (id) => {
  const { t } = useT();
  return t(id) !== id ? <HtmlTypography>{t(id)}</HtmlTypography> : undefined;
};

const missigProjectBoardRole = () => {
  function unFilledRoles() {
    let requiredRoles = ["executive", "seniorSupplier", "seniorUser"];
    if (projectMembers == undefined || projectMembers == []) {
      return requiredRoles;
    }

    for (let i = 0; i < requiredRoles.length; i++) {
      projectMembers.forEach((member) => {
        member.role.forEach((role) => {
          if (role == requiredRoles[i]) {
            requiredRoles.splice(i, 1);
          }
        });
      });
    }
    return requiredRoles.length > 0 ? [requiredRoles[0]] : [];
  }
};

function identifyWarning(definedRoles, t) {
  const roles = isNil(definedRoles)
    ? definedRoles
    : definedRoles.filter((item) => item.role !== undefined);
  if (
    isNil(roles) ||
    roles.find((item) => item.role.includes("executive")) === undefined
  ) {
    return {
      board: t("project.organization.projectBoard.roles.executive.missing"),
    };
  } else if (
    isNil(roles) ||
    roles.find((item) => item.role.includes("projectManager")) === undefined
  ) {
    console.log("Mangler prosjektleder");
    return {
      management: t(
        "project.organization.projectManagement.roles.projectManager.missing",
      ),
    };
  } else if (
    !isNil(roles) &&
    roles.find((item) => item.role.includes("seniorUser")) === undefined
  ) {
    return {
      board: t("project.organization.projectBoard.roles.seniorUser.missing"),
    };
  } else if (
    !isNil(roles) &&
    roles.find((item) => item.role.includes("seniorSupplier")) === undefined
  ) {
    return {
      board: t(
        "project.organization.projectBoard.roles.seniorSupplier.missing",
      ),
    };
  } else if (
    isNil(roles) ||
    roles.find((item) => item.role.includes("teamManager")) === undefined
  ) {
    return {
      team: t("project.organization.deliveryTeam.roles.teamManager.missing"),
    };
  } else {
    return {};
  }
}

function identifyProgrammeWarning(definedRoles, t) {
  const roles = isNil(definedRoles)
    ? definedRoles
    : definedRoles.filter((item) => item.role !== undefined);

  if (
    isNil(roles) ||
    roles.find((item) => item.role.includes("programmeManager")) === undefined
  ) {
    return {
      board: t(
        "programme.organization.programmeBoard.roles.programmeManager.missing",
      ),
    };
  } else if (
    isNil(roles) ||
    roles.find((item) => item.role.includes("seniorResponsibleOwner")) ===
      undefined
  ) {
    console.log("Mangler prosjektleder");
    return {
      board: t(
        "programme.organization.programmeBoard.roles.seniorResponsibleOwner.missing",
      ),
    };
  } else {
    return {};
  }
}

function identifyProductWarning(definedRoles, t) {
  const roles = isNil(definedRoles)
    ? definedRoles
    : definedRoles.filter((item) => item.role !== undefined);

  if (
    isNil(roles) ||
    roles.find((item) => item.role.includes("executive")) === undefined
  ) {
    return {
      board: t("product.organization.productBoard.roles.executive.missing"),
    };
  } else if (
    isNil(roles) ||
    roles.find((item) => item.role.includes("productManager")) === undefined
  ) {
    return {
      board: t(
        "product.organization.productBoard.roles.productManager.missing",
      ),
    };
  } else {
    return {};
  }
}

export const Organization = () => {
  const { id = "" } = useParams();
  const type = useMatch(clientUrl(uris.project.organization, { id }) + "/*")
    ? "project"
    : useMatch(clientUrl(uris.programme.organization, { id }) + "/*")
      ? "programme"
      : "product";
  const tKey =
    type === "project"
      ? "project.organization"
      : type === "programme"
        ? "programme.organization"
        : "product.organization";
  const { data: roles = [], refetch } = useQ(
    `project-${id}-roles`,
    () => project.roles({ id }),
    { enabled: type === "project" },
  );
  const { data: programmeRoles = [], refetch: refetchProgramme } = useQ(
    `programme-${id}-roles`,
    () => programme.roles({ id }),
    { enabled: type === "programme" },
  );
  const { data: productRoles = [], refetch: refetchProduct } = useQ(
    `product-${id}-roles`,
    () => product.roles({ id }),
    { enabled: type === "product" },
  );

  const { t } = useT();

  return (
    <Page isLoading={false} title={""}>
      <Section title={t("project.organization.title")}>
        <Typography>{t(tKey + ".description")}</Typography>
        <Typography>{t(tKey + ".additionalInformation")}</Typography>
        {type === "project" ? (
          <>
            <OrganizationCategory
              sectionTitle={t("project.organization.projectBoard.title")}
              addProjectMemberText={t("project.organization.projectBoard.add")}
              roles={roles}
              onChange={(board) => refetch()}
              help={sectionHelp("project.organization.projectBoard.help")}
              validRoles={["executive", "seniorUser", "seniorSupplier"]}
              category="projectBoard"
              warning={identifyWarning(roles, t).board}
              roleOrder={["executive", "seniorUser", "seniorSupplier"]}
              refetch={refetch}
              type={type}
              tKey={"project.organization"}
            />
            <OrganizationCategory
              sectionTitle={t("project.organization.projectAssurance.title")}
              addProjectMemberText={t(
                "project.organization.projectAssurance.add",
              )}
              roles={roles}
              validRoles={[
                "businessAssurance",
                "userAssurance",
                "supplierAssurance",
              ]}
              category="projectAssurance"
              onChange={(board) => refetch()}
              help={sectionHelp("project.organization.projectAssurance.help")}
              refetch={refetch}
              tKey={"project.organization"}
            />
            <OrganizationCategory
              sectionTitle={t("project.organization.projectManagement.title")}
              addProjectMemberText={t(
                "project.organization.projectManagement.add",
              )}
              roles={roles}
              onChange={(management) => refetch()}
              help={sectionHelp("project.organization.projectManagement.help")}
              validRoles={[
                "projectManager",
                "projectSupport",
                "changeAuthority",
                "stakeholder",
              ]}
              category="projectManagement"
              warning={identifyWarning(roles, t).management}
              refetch={refetch}
              tKey={"project.organization"}
            />
            <OrganizationCategory
              sectionTitle={t("project.organization.deliveryTeam.title")}
              addProjectMemberText={t("project.organization.deliveryTeam.add")}
              roles={roles}
              help={sectionHelp("project.organization.deliveryTeam.help")}
              onChange={(team) => refetch()}
              validRoles={["teamManager", "teamMember"]}
              category="deliveryTeam"
              warning={identifyWarning(roles, t).team}
              refetch={refetch}
              tKey={"project.organization"}
            />
          </>
        ) : type === "programme" ? (
          <>
            <OrganizationCategory
              sectionTitle={t(tKey + ".programmeBoard.title")}
              addProjectMemberText={t(tKey + ".programmeBoard.add")}
              roles={programmeRoles}
              onChange={(board) => refetchProgramme()}
              help={sectionHelp(tKey + ".programmeBoard.help")}
              validRoles={[
                "programmeManager",
                "seniorResponsibleOwner",
                "businessChangeManager",
              ]}
              category="programmeBoard"
              warning={identifyProgrammeWarning(programmeRoles, t).board}
              roleOrder={[
                "programmeManager",
                "seniorResponsibleOwner",
                "businessChangeManager",
              ]}
              refetch={refetchProgramme}
              type={type}
              tKey={tKey}
            />
            <OrganizationCategory
              sectionTitle={t(tKey + ".programmeOffice.title")}
              addProjectMemberText={t(tKey + ".programmeOffice.add")}
              roles={programmeRoles}
              onChange={(board) => refetchProgramme()}
              help={sectionHelp(tKey + ".programmeOffice.help")}
              validRoles={["stakeholder", "teamMember"]}
              category="programmeOffice"
              warning={identifyProgrammeWarning(programmeRoles, t).office}
              roleOrder={["stakeholder", "teamMember"]}
              refetch={refetchProgramme}
              type={type}
              tKey={tKey}
            />
          </>
        ) : (
          <>
            <OrganizationCategory
              sectionTitle={t(tKey + ".productBoard.title")}
              addProjectMemberText={t(tKey + ".productBoard.add")}
              roles={productRoles}
              onChange={(board) => refetchProduct()}
              help={sectionHelp(tKey + ".productBoard.help")}
              validRoles={["executive", "productManager"]}
              category="productBoard"
              warning={identifyProductWarning(productRoles, t).board}
              roleOrder={["executive", "productManager"]}
              refetch={refetchProduct}
              type={type}
              tKey={tKey}
            />
            <OrganizationCategory
              sectionTitle={t(tKey + ".productTeam.title")}
              addProjectMemberText={t(tKey + ".productTeam.add")}
              roles={productRoles}
              onChange={(board) => refetchProduct()}
              help={sectionHelp(tKey + ".productTeam.help")}
              validRoles={["teamManager", "stakeholder", "teamMember"]}
              category="productTeam"
              warning={identifyProductWarning(productRoles, t).team}
              roleOrder={["teamManager", "stakeholder", "teamMember"]}
              refetch={refetchProduct}
              type={type}
              tKey={tKey}
            />
          </>
        )}
      </Section>
    </Page>
  );
};
