import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { useT } from "../hooks/useT";
import { SummaryCard } from "./project/SummaryCard";
import { Stat } from "./Stat";
import { isNil, path, propOr } from "ramda";
import { DatePretty } from "./DatePretty";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { clientUrl } from "../util/routing";
import { uris } from "../config/nav";

function level2AndAbove(level) {
  return isNil(level) || level >= 2;
}

export const StatusSummaryCard = ({ data, type }) => {
  const { t } = useT();
  const { status, level } = data;

  return (
    <SummaryCard>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        sx={{ height: "100%" }}
      >
        <Stack>
          <Stack direction="row" justifyContent="center" spacing={1}>
            <Typography variant="h2" textAlign="center" mb={1}>
              {t("project.overview.status.title")}
            </Typography>
            <Stat
              status={propOr({}, "value", status)}
              size={"md"}
              className={"pd-l-xs"}
            />
          </Stack>
          {!isNil(status) && !isNil(status.latestHighlightReportId) && (
            <Link
              to={
                type === "products"
                  ? "/external/products/" +
                    data.id +
                    "/reports/?id=" +
                    data.status.latestHighlightReportId
                  : type === "releases"
                    ? "/external/releases/" +
                      data.id +
                      "/reports/?id=" +
                      data.status.latestHighlightReportId
                    : clientUrl(
                        type === "projects"
                          ? uris.project.progressFull
                          : uris.programme.knowledgeFull,
                        {
                          id:
                            type === "programmes"
                              ? data.uri?.replace("/programmes/", "")
                              : data.id,
                          type: "highlight",
                          reportId: status.latestHighlightReportId,
                        },
                      )
              }
              style={{ textDecoration: "none" }}
              onClick={() =>
                useNavigate(
                  type === "products"
                    ? "/external/products/" + data.id + "/highlight"
                    : type === "releases"
                      ? "/external/releases/" + data.id + "/highlight"
                      : clientUrl(
                          type === "projects"
                            ? uris.project.progressFull
                            : uris.programme.knowledgeFull,
                          {
                            id:
                              type === "programmes"
                                ? data.uri?.replace("/programmes/", "")
                                : data.id,
                            type: "highlight",
                            reportId: data.status.latestHighlightReportId,
                          },
                        ),
                )
              }
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography>
                  Latest Highlight Report
                  {!!data.status.date && (
                    <>
                      {" "}
                      (<DatePretty date={data.status.date} />)
                    </>
                  )}
                </Typography>
                <ArrowCircleRightOutlinedIcon
                  sx={{ color: "#9A9EB1 !important" }}
                />
              </Stack>
            </Link>
          )}
        </Stack>
        {level2AndAbove(level) ? (
          <Stack
            spacing={2}
            sx={{ mt: 2, width: "100%" }}
            direction="row"
            justifyContent="space-between"
          >
            <Stat
              size={"md"}
              status={path(["details", "benefits", "value"], status)}
              subtitle={t("generic.status.benefits")}
            />
            <Stat
              size={"md"}
              status={path(["details", "time", "value"], status)}
              subtitle={t("generic.status.time")}
            />
            <Stat
              size={"md"}
              status={path(["details", "cost", "value"], status)}
              subtitle={t("generic.status.cost")}
            />
            <Stat
              size={"md"}
              status={path(["details", "scope", "value"], status)}
              subtitle={t("generic.status.scope")}
            />
            <Stat
              size={"md"}
              status={path(["details", "quality", "value"], status)}
              subtitle={t("generic.status.quality")}
            />
            <Stat
              size={"md"}
              status={path(["details", "risk", "value"], status)}
              subtitle={t("generic.status.risk")}
            />
          </Stack>
        ) : null}
      </Stack>
    </SummaryCard>
  );
};
