import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const basicTheme = createTheme();
export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#111827",
      },
      secondary: {
        main: "#6B7280",
      },
      success: {
        main: "#47B857",
        contrastText: "#fff",
      },
      warning: {
        main: "#F4C403",
        contrastText: "#111827",
      },
      error: {
        main: "#F85252",
        contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: '"Lato", sans-serif',
      h2: {
        fontSize: "20px",
        lineHeight: 24,
        fontWeight: 700,
      },
      h4: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#6B7280",
      },
      h5: {
        fontSize: "12px",
        fontWeight: 700,
        color: "#6B7280",
        textTransform: "uppercase",
      },
    },
    components: {
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          root: {
            border: "1px solid #E5E7EB",
            borderRadius: "8px",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "6px",
            boxShadow: "none",
            textTransform: "none",
            height: "39px",
            padding: "0 25px",
          },
          startIcon: {
            "& > *:first-child": {
              fontSize: "30px!important",
            },
          },
          text: {
            textTransform: "none",
            fontWeight: "bold",
            whiteSpace: "nowrap",
            minWidth: "auto",
          },
          textSecondary: {
            color: "#6B7280",
            backgroundColor: "#F3F4F6",
            fontWeight: "normal",
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            ":before": {
              display: "none",
            },
            "&:not(:last-child)": {
              borderBottom: 0,
            },
            "&:first-of-type": {
              borderTopLeftRadius: basicTheme.shape.borderRadius * 2,
              borderTopRightRadius: basicTheme.shape.borderRadius * 2,
            },
            "&:last-of-type": {
              borderBottomLeftRadius: basicTheme.shape.borderRadius * 2,
              borderBottomRightRadius: basicTheme.shape.borderRadius * 2,
            },
            boxShadow: "none",
            border: "1px solid #E5E7EB",
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            minHeight: "72px",
            userSelect: "text",
            "&.Mui-focusVisible": {
              backgroundColor: "transparent",
            },
          },
          content: {
            width: "100%",
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 42,
            height: 26,
            padding: 0,
            "& .MuiSwitch-switchBase": {
              padding: 0,
              margin: 2,
              transitionDuration: "300ms",
              "&.Mui-checked": {
                transform: "translateX(16px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                  backgroundColor:
                    basicTheme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
                  opacity: 1,
                  border: 0,
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                  opacity: 0.5,
                },
              },
              "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: "#33cf4d",
                border: "6px solid #fff",
              },
              "&.Mui-disabled .MuiSwitch-thumb": {
                color:
                  basicTheme.palette.mode === "light"
                    ? basicTheme.palette.grey[100]
                    : basicTheme.palette.grey[600],
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: basicTheme.palette.mode === "light" ? 0.7 : 0.3,
              },
            },
            "& .MuiSwitch-thumb": {
              boxSizing: "border-box",
              width: 22,
              height: 22,
            },
            "& .MuiSwitch-track": {
              borderRadius: 26 / 2,
              backgroundColor:
                basicTheme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
              opacity: 1,
              transition: basicTheme.transitions.create(["background-color"], {
                duration: 500,
              }),
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "outlined",
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "6px",
            border: "1px solid #D1D5DB",
            height: "38px",
            backgroundColor: "#fff",
          },
          notchedOutline: {
            border: "none",
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: "#9A9EB1",
            cursor: "pointer",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: "#ccc",
            color: "#fff !important",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            height: "40px",
          },
        },
      },
      MuiStepButton: {
        styleOverrides: {
          root: {
            "&.Mui-disabled svg": {
              color: "#e2e5e8",
            },
            "&.Mui-disabled span": {
              color: "#D1D5DB",
            },
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          iconContainer: {
            "& > svg": {
              color: "#D1D5DB",
              width: "40px",
              height: "40px",
            },
            "&.Mui-active > svg": {
              color: "#15B5F2",
            },
          },
          label: {
            textTransform: "uppercase",
            color: "#111827",
            fontSize: "14px",
            fontWeight: 700,
            "&.Mui-active": {
              fontWeight: 700,
            },
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          className: "ql-editor",
        },
        styleOverrides: {
          root: {
            wordBreak: "normal",
            // Reset specific styles from ql-editor
            "&.ql-editor": {
              minHeight: "unset",
              boxSizing: "unset",
              counterReset: "unset",
              height: "unset",
              padding: "unset",
              whiteSpace: "unset",
              wordWrap: "unset",
              outline: "unset",
              overflowY: "unset",
              tabSize: "unset",
              textAlign: "",
            },
          },
        },
      },
    },
  }),
);
