import React, { useEffect, useMemo } from "react";
import { Page } from "@/layout/Page";
import { Section } from "@/layout/Section";
import { PrimaryButton, SecondaryButton } from "@/base/Button";
import { FormDataProvider } from "@/base/form/data-context/FormDataContext";
import * as Yup from "yup";
import { useT } from "@/hooks/useT";
import { useMutation } from "react-query";
import {
  portfolio,
  product,
  location,
  projectType,
  category,
  department,
  currency,
  programme,
} from "@/queries";
import { uris } from "@/config/nav";
import { clientUrl } from "@/util/routing";
import { Hide } from "@/base/responsive/Hide";
import { NoMobileView } from "@/base/responsive/NoMobileView";
import { useQ } from "@/hooks/useQ";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormTextField } from "@/base/form/mui/FormTextField";
import { FormSelect } from "@/base/form/mui/FormSelect";
import { Stack, Grid } from "@mui/material";
import { useToast } from "@/hooks/useToast";

export const EditProductProperties = () => {
  const { t } = useT();
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const { isLoading, data: productData = {} } = useQ(`product-${id}`, () =>
    product.single({ id }),
  );
  const { data: portfolios = [], isLoading: isLoadingPortfolios } = useQ(
    `portfolios`,
    () => portfolio.list(),
  );
  const {
    data: locations = [],
    refetch: refetchLocations,
    isLoading: isLoadingLocations,
  } = useQ(`locations`, () => location.list());
  const {
    data: currencies = [],
    refetch: refetchCurrencies,
    isLoading: isLoadingCurrencies,
  } = useQ(`currencies`, () => currency.list());

  function getOptions(array) {
    return array.map((e) => {
      return { label: e.name, value: e.id };
    });
  }

  const editProperties = useMutation(product.editProperties, {
    onSuccess: (data) => {
      navigate(clientUrl(uris.product.single, { id: productData.id }));
      toast.success("Product updated successfully");
    },
  });

  const schema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t("initiative.addEditForm.fields.name.requiredError")),
    identifier: Yup.string().trim(),
    portfolio: Yup.string().trim(),
    currency: Yup.string().trim(),
    location: Yup.string().trim(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: productData?.name ?? "",
      identifier: productData?.identifier ?? "",
      portfolio: productData?.portfolio?.id ?? "",
      location: productData?.location ?? "",
      currency: productData?.currency ?? "",
    },
  });
  const { handleSubmit, reset, control, setValue } = formData;

  useEffect(() => {
    reset({
      name: productData.name,
      identifier: productData.identifier,
      portfolio: productData.portfolio?.id,
      location: productData.location,
      currency: productData.currency,
    });
  }, [reset, productData, locations, portfolios, currencies]);

  return (
    <Page>
      <NoMobileView show={"sm"} />

      <Hide hide={"sm"}>
        <Section title={t("product.editProperties.title")}>
          <FormDataProvider
            formKey="initiative.addEditForm.fields"
            {...formData}
          >
            <form
              onSubmit={handleSubmit((data) => {
                editProperties.mutate({
                  id: productData.id,
                  data: {
                    ...data,
                    programme: data.programme === "none" ? "" : data.programme,
                  },
                });
              })}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormTextField name="name" />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormTextField name="identifier" />
                </Grid>

                {portfolios.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="portfolio"
                      options={getOptions(portfolios)}
                    />
                  </Grid>
                ) : null}

                {currencies.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="currency"
                      options={currencies.map((e) => {
                        return { label: e, value: e };
                      })}
                    />
                  </Grid>
                ) : null}

                {locations.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="location"
                      options={getOptions(locations)}
                    />
                  </Grid>
                ) : null}
              </Grid>
              <Stack mt={2} direction="row" justifyContent="flex-end">
                <SecondaryButton
                  onClick={() =>
                    navigate(
                      clientUrl(uris.product.single, { id: productData.id }),
                    )
                  }
                >
                  {t("generic.button.cancel")}
                </SecondaryButton>
                <PrimaryButton className={"mg-l-nm"} type={"submit"}>
                  {t("product.editProperties.update")}
                </PrimaryButton>
              </Stack>
            </form>
          </FormDataProvider>
        </Section>
      </Hide>
    </Page>
  );
};
