import React, { useEffect, useRef } from "react";
import { useT } from "@/hooks/useT";
import { useParams } from "react-router-dom";
import { LoginTemplate } from "@/pages/login/Login";
import { Button, TextField, Typography } from "@mui/material";

const ConfirmCodeForm = () => {
  const { code } = useParams();
  const { t } = useT();

  return (
    <form action={`/api/login/${code}`} method="POST">
      <input name="token" value={code} type="hidden" />
      <input name="approach" value="magicLink" type="hidden" />
      <Button variant="contained" sx={{ mt: 2 }} type="submit">
        {t("loginForm.submit")}
      </Button>
    </form>
  );
};

export const LinkLoginLander = () => {
  const { t } = useT();
  return (
    <LoginTemplate step={3}>
      <ConfirmCodeForm />
    </LoginTemplate>
  );
};
